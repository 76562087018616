import StaffCard from '@/app/components/ui/Cards/StaffCard';
import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';
import type { ContentfulStaffCard } from '@/app/types/contentful/types';

interface StaffComponentProps {
  content: {
    staffComponent: {
      title: string;
      description: string;
      staffCardsCollection: {
        items: ContentfulStaffCard[];
      };
      spacingTop: number;
      spacingBottom: number;
    };
  };
}

export default function StaffComponent({ content }: StaffComponentProps) {
  const { staffComponent: {title, description, staffCardsCollection, spacingTop, spacingBottom} } = content;

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  return (
    <section className={`container grid h-full w-full grid-cols-12 gap-y-20 ${paddingTop} ${paddingBottom}`}>
      <article className="col-span-6 row-start-1 flex flex-col items-start justify-center gap-y-4 rounded-lg text-WHITE lg:col-span-10 md:col-span-full">
        <h1 className="text-H1 sm:text-H3">{title}</h1>
        <p className="text-Body-Large sm:text-Body">{description}</p>
      </article>

      <article className="col-span-full row-start-2 grid grid-cols-12 gap-10 text-WHITE lg:gap-y-20 md:grid-cols-12 md:gap-3 md:gap-y-6 sm:gap-y-12">
        {staffCardsCollection?.items.map((card: ContentfulStaffCard, index: number) => (
          <StaffCard key={card._id + index} card={card} />
        ))}
      </article>
    </section>
  );
}
