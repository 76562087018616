import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types';
import clsx from 'clsx';
import { ArrowUpRight } from 'lucide-react';
import Image from 'next/image';
import { Link } from 'next-view-transitions';
import React, { ReactNode } from 'react';

import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';
import type { HeroComponentSingleColumnProps } from '@/app/types/contentful/types';

import ClientCTAButton from '../Buttons/ClientCTAButton';
import { SvgLinesComponent } from '../SvgLinesComponent';
import VideoPlayer from '../Video/VideoPlayer';

const gradientTypeClasses: { [key: string]: string | string[] } = {
  None: 'bg-BLACK',
  'Slate Blue': 'bg-SLATE_BLUE',
  'Off Black': 'bg-OFF-BLACK',
  'Purple Top': 'bg-gradient-top-purple bg-top',
  'Blue Top': 'bg-gradient-top-blue bg-top',
  'Purple Center': 'bg-gradient-center-purple bg-center',
  'Blue Center': 'bg-gradient-center-blue bg-center',
  'Purple Bottom': 'bg-gradient-bottom-purple bg-bottom',
  'Blue Bottom': 'bg-gradient-bottom-blue bg-bottom',
  'Purple Top/Bottom': ['bg-gradient-top-purple bg-top', 'bg-gradient-bottom-purple bg-bottom'],
  'Blue Top/Bottom': ['bg-gradient-top-blue bg-top', 'bg-gradient-bottom-blue bg-bottom'],
};

export default function HeroComponentSingleColumn({ content }: HeroComponentSingleColumnProps) {
  const {
    heroComponentSingleColumn: {
      title,
      gradientType,
      showGridLines,
      heroImageDesktop,
      heroImageMobile,
      customHeading,
      useCustomHeading,
      spacingTop,
      spacingBottom,
    },
  }: any = content;

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  const renderGradient = () => {
    const gradientClass = gradientTypeClasses[gradientType];

    if (Array.isArray(gradientClass)) {
      return (
        <>
          <div className={clsx('pointer-events-none absolute -z-10 h-full w-screen bg-cover bg-no-repeat', gradientClass[0])} />
          <div className={clsx('pointer-events-none absolute -z-10 h-full w-screen bg-cover bg-no-repeat', gradientClass[1])} />
        </>
      );
    }

    return gradientClass ? (
      <div className={clsx('pointer-events-none absolute -z-20 h-full w-screen bg-cover bg-no-repeat', gradientClass)} />
    ) : null;
  };

  const renderList = (node: any, ordered: boolean, isNested: boolean = false): React.ReactNode => {
    const Tag: 'ol' | 'ul' = ordered ? 'ol' : 'ul';

    return (
      <Tag
        className={clsx('col-span-full h-full w-full pb-2 pl-8', {
          'list-decimal': ordered && !isNested,
          'list-disc': !ordered && !isNested,
          'list-none': isNested,
        })}
      >
        {node.content.map((item: any, index: number) => renderListItem(item, index, ordered, isNested))}
      </Tag>
    );
  };

  const renderListItem = (node: any, index: number, ordered: boolean, isNested: boolean): React.ReactNode => {
    let marker: string = '';
    if (isNested && ordered) {
      marker = String.fromCharCode(65 + index) + '.';
    }

    const key: string = `${index}-${marker}${node.content[0]?.content[0]?.value?.slice(0, 20) || ''}`;

    return (
      <li
        key={key}
        className={clsx(
          'w-full whitespace-pre-wrap pb-3 text-Body-Large-Medium font-normal text-WHITE/90 md:text-Body-Large sm:text-Body min-lg:max-w-[60ch]',
          { 'marker:text-Body-Large-Medium marker:md:text-Body-Large marker:sm:text-[0.9rem]': !isNested || !ordered },
          { 'pt-3 last:pb-0': isNested },
        )}
      >
        {isNested && ordered && <span className="inline-block w-5 sm:w-20px">{marker}</span>}

        {node.content.map((content: any, contentIndex: number) => {
          if (content.nodeType === BLOCKS.PARAGRAPH) {
            return (
              <span className="mt-5" key={`${key}-paragraph-${contentIndex}`}>
                {content.content[0].value}
              </span>
            );
          } else if (content.nodeType === BLOCKS.OL_LIST || content.nodeType === BLOCKS.UL_LIST) {
            return renderList(content, content.nodeType === BLOCKS.OL_LIST, true);
          }
          return null;
        })}
      </li>
    );
  };

  const options: any = {
    renderNode: {
      [BLOCKS.HEADING_1]: (_node: any, children: any) => (
        <h2
          className={clsx(
            'col-span-full text-balance justify-self-center whitespace-pre-wrap pb-5 text-center leading-[1.15]',
            'text-Display text-WHITE/90 lg:text-H1 md:text-H2 sm:text-H3',
          )}
        >
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_2]: (_node: any, children: any) => (
        <h3
          className={clsx(
            'col-span-full max-w-[35ch] whitespace-pre-wrap text-balance pb-5 pt-2 text-H1 text-WHITE/90',
            'md:text-H2 sm:col-span-full sm:text-H3',
          )}
        >
          {children}
        </h3>
      ),
      [BLOCKS.HEADING_3]: (_node: any, children: any) => (
        <h4
          className={clsx(
            'col-span-full max-w-[30ch] whitespace-pre-wrap text-balance pb-4 pt-4 text-H2 font-bold text-WHITE/90',
            'md:text-H3 sm:text-H4',
          )}
        >
          {children}
        </h4>
      ),
      [BLOCKS.HEADING_4]: (_node: any, children: any) => (
        <h5
          className={clsx(
            'col-span-full whitespace-pre-wrap text-balance pb-3 pt-3 text-H3 font-bold text-WHITE/90',
            'md:text-H4 sm:col-span-full sm:text-Body-Large',
          )}
        >
          {children}
        </h5>
      ),
      [BLOCKS.HEADING_5]: (_node: any, children: any) => (
        <h6
          className={clsx(
            'col-span-full whitespace-pre-wrap text-balance pb-2 pt-3 text-H4 font-bold leading-tight tracking-wide text-WHITE/90',
            'md:text-Body-Large sm:col-span-full sm:text-Body',
          )}
        >
          {children}
        </h6>
      ),
      [BLOCKS.HEADING_6]: (_node: any, children: any) => (
        <h1
          className={clsx(
            'col-span-6 whitespace-pre-wrap text-balance pb-2 pt-2 text-Body-Large font-semibold text-GREEN-500',
            'md:text-Body sm:col-span-full sm:text-Body',
          )}
        >
          {children}
        </h1>
      ),
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => (
        <p className="w-full max-w-[80ch] whitespace-pre-wrap text-balance pb-4 text-Body font-normal text-WHITE/90 md:text-Body-Large sm:text-Body">
          {children}
        </p>
      ),
      [BLOCKS.QUOTE]: (_node: any, children: any) => (
        <blockquote className="whitespace-pre-wrap text-balance rounded-md bg-slate-700 px-6 py-6 text-Body-Large font-normal text-WHITE md:text-Body">
          {children}
        </blockquote>
      ),
      [BLOCKS.HR]: (_node: any) => <hr className="border-t-2 border-WHITE/50 py-4" />,
      [BLOCKS.UL_LIST]: (node: any) => renderList(node, false),
      [BLOCKS.OL_LIST]: (node: any) => renderList(node, true),
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const assetId = node.data.target.sys.id;
        const asset = customHeading.links.assets.block.find((asset: any) => asset.sys.id === assetId);

        if (!asset) return null;

        if (asset.url.includes('.mp4')) {
          const aspectRatio = asset.height / asset.width;
          return (
            <article className="container mt-16 grid h-full w-full grid-cols-12 md:mt-10">
              <div className="col-span-full">
                <VideoPlayer url={asset.url} aspectRatio={aspectRatio} />
              </div>
            </article>
          );
        } else if (
          asset.url.includes('.jpg') ||
          asset.url.includes('.png') ||
          asset.url.includes('.gif') ||
          asset.url.includes('.svg') ||
          asset.url.includes('.webp')
        ) {
          return (
            <Image
              width={asset.width}
              height={asset.height}
              src={asset.url}
              alt={asset.description || 'Embedded asset'}
              className="my-10 h-auto w-full min-w-[250px] max-w-[1000px] rounded-md md:my-5"
            />
          );
        }

        return null;
      },
      [INLINES.HYPERLINK]: (node: any, children: any) => {
        const href = node.data.uri;
        return (
          <Link
            href={href}
            target="_blank"
            className="inline-flex items-center gap-x-1 transition-all duration-300 hover:text-BLUE-400/80 hover:underline focus:text-BLUE-400/80 active:text-BLUE-400/60 active:underline"
          >
            {children}
            <ArrowUpRight className="h-4 w-4" />
          </Link>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node: any) => {
        const entryId = node.data.target.sys.id;
        const button = customHeading.links.entries.inline.find((entry: any) => entry.sys.id === entryId);

        if (!button) return null;

        const isFirstButton = customHeading.links.entries.inline.indexOf(button) === 0;
        return (
          <ClientCTAButton
            key={button.sys.id}
            buttonData={button}
            className={clsx('mt-2 inline-block', {
              'ml-5': !isFirstButton && customHeading.links.entries.inline.length > 1,
            })}
          />
        );
      },
    },
    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => <strong className="font-bold text-GREEN-500">{text}</strong>,
      [MARKS.ITALIC]: (text: ReactNode) => <em className="italic">{text}</em>,
      [MARKS.UNDERLINE]: (text: ReactNode) => <span className="underline">{text}</span>,
      [MARKS.SUPERSCRIPT]: (text: ReactNode) => <sup className="text-xs">{text}</sup>,
      [MARKS.SUBSCRIPT]: (text: ReactNode) => <sub className="text-xs">{text}</sub>,
      [MARKS.STRIKETHROUGH]: (text: ReactNode) => <span className="line-through">{text}</span>,
    },
  };

  return (
    <section className={`relative grid h-full w-full grid-cols-12 grid-rows-1 ${paddingTop} ${paddingBottom} overflow-clip`}>
      {/* Gradients */}
      {renderGradient()}
      {/* SVG lines */}
      {showGridLines && <SvgLinesComponent />}

      {/* Rich Text Custom Heading */}
      {useCustomHeading && (
        <article className="col-start-2 col-end-12 row-start-1 flex animate-fade-in-up flex-col items-center justify-center text-center">
          {documentToReactComponents(customHeading.json as Document, options)}
        </article>
      )}

      {!useCustomHeading && !title && (
        <>
          <article className="col-start-2 col-end-12 mb-20 flex animate-fade-in-up flex-col items-center justify-center justify-self-center text-center sm:mb-10">
            {title && (
              <h2 className="mb-4 w-full max-w-[25ch] justify-self-center text-balance text-center text-Display text-WHITE lg:max-w-[25ch] lg:text-H1 md:text-Display-Mobile sm:text-H3">
                {title}
              </h2>
            )}
          </article>
        </>
      )}

      <article className="col-span-full row-start-1 items-center justify-center">
        {heroImageDesktop && (
          <Image
            src={heroImageDesktop.url}
            alt={heroImageDesktop.description || heroImageDesktop.title}
            width={heroImageDesktop.width}
            height={heroImageDesktop.height}
            priority
            sizes="(max-width: 768px) 100vw, 768px"
            draggable={false}
            className="h-full w-full object-cover md:hidden"
          />
        )}

        {heroImageMobile && (
          <Image
            src={heroImageMobile.url}
            alt={heroImageMobile.description || heroImageDesktop.title}
            width={heroImageMobile.width}
            height={heroImageMobile.height}
            priority
            sizes="(max-width: 768px) 100vw, 768px"
            draggable={false}
            className="hidden h-full w-full object-cover md:block"
          />
        )}
      </article>
    </section>
  );
}
