'use client';

import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import React from 'react';

import { componentResolver } from '../componentResolver';

export function Wrapper({ data }: any) {
  const updated = useContentfulLiveUpdates(data);

  return updated.sectionsCollection.items.map((item: any, index: number) => {
    const Component = componentResolver(item.__typename);
    return (
      <React.Fragment key={item.sys.id}>
        <Component
          key={`${item.sys.id}+${index}`}
          content={item}
          // locationData={items[0]}
        />
      </React.Fragment>
    );
  });
}
