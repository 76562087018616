import clsx from 'clsx';

export default function LocationItem({ card, className }: any) {
  const { country, address } = card;
  return (
    <article className={clsx(className, 'text-WHITE whitespace-pre-wrap w-full h-auto')}>
      <h1 className='text-H1-Mobile mb-4 sm:text-H2-Mobile'>{country}</h1>
      <p className='text-Body leading-6'>{address}</p>
    </article>
  );
}
