import clsx from 'clsx';
import { StarSolid } from 'iconoir-react';
import Image from 'next/image';

import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';

export default function ReviewAppraisalComponent({ content }: any) {
  const {
    reviewAppraisalComponent: { label, title, description, reviewCardsCollection, spacingTop, spacingBottom },
  } = content;

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  return (
    <section className={`container grid h-full w-full grid-cols-12 place-items-center gap-y-20 ${paddingTop} ${paddingBottom}`}>
      <article className="col-span-full row-start-1 flex flex-col gap-y-4 rounded-lg text-center text-WHITE md:items-start md:text-left">
        <h1 className="text-Pill text-GREEN-500">{label}</h1>
        <h2 className="text-H1 md:text-H1-Mobile">{title}</h2>
        <p className="max-w-[55ch] text-Body-Large md:text-Body">{description}</p>
      </article>

      <article className="col-span-full row-start-2 grid grid-cols-12 gap-y-52 md-lg:gap-y-10">
        {reviewCardsCollection.items.map((card: any, index: number) => {
          return <ReviewCard key={`${card._id}-${index}`} card={card} index={index} />;
        })}
      </article>
    </section>
  );
}

function ReviewCard({ card, index }: any) {
  const { _id, review, rating, color, layout } = card; 
  return (
    <div
      className={clsx('col-span-5 h-auto w-full rounded-lg border border-WHITE/40 p-8 flex flex-col justify-between', {
        'bg-GREEN-950': color === 'Green',
        'bg-PURP-950': color === 'Purple',
        'bg-[#0E245D]': color === 'Blue',
        'translate-x-10 md-lg:col-span-full md-lg:translate-x-0': index === 0,
        'translate-x-60 translate-y-28 md-xl:translate-x-52 lg:translate-x-44 md-lg:col-span-full md-lg:row-start-2 md-lg:translate-x-0 md-lg:translate-y-0':
          index === 1,
        'row-start-2 translate-x-56 md-lg:col-span-full md-lg:row-start-3 md-lg:translate-x-0': index === 2,
      })}
      key={`${_id}-${index}`}
    >
      <p
        className={clsx('text-Body-Large font-semibold md:text-Body', {
          'text-[#63F2BC]': color === 'Green',
          'text-[#F4C7FF]': color === 'Purple',
          'text-[#B5DEFF]': color === 'Blue',
        })}
      >
        {review}
      </p>
      {rating && (
        <div>
          {Array.from({ length: rating }, (_: any, i: number) => (
            <StarSolid
              key={card._id + i}
              className={clsx('inline-block h-5 w-5 mt-5', {
                'text-[#63F2BC]': color === 'Green',
                'text-[#F4C7FF]': color === 'Purple',
                'text-BLUE-400': color === 'Blue',
              })}
            />
          ))}
        </div>
      )}
      {(() => {
        switch (layout) {
          case 'Signature + Logo':
            return <SignatureLogo card={card} />;
          case 'Logo':
            return <Logo card={card} />;
          case 'Signature':
            return <Signature card={card} />;
          default:
            return null;
        }
      })()}
    </div>
  );
}

function SignatureLogo({ card }: any) {
  const { reviewerLogo, reviewerName, color } = card;
  return (
    <>
      <p
        className={clsx('mt-15 text-Body-Large font-semibold', {
          'text-[#63F2BC]': color === 'Green',
          'text-[#F4C7FF]': color === 'Purple',
          'text-[#B5DEFF]': color === 'Blue',
        })}
      >
        {reviewerName}
      </p>
      {reviewerLogo && (
        <Image
          src={reviewerLogo.url}
          alt={reviewerLogo.description || reviewerLogo.title}
          width={reviewerLogo.width}
          height={reviewerLogo.height}
          sizes="(max-width: 768px) 100vw, 768px"
          draggable={false}
          className="ml-auto mt-15 object-contain"
        />
      )}
    </>
  );
}

function Logo({ card }: any) {
  const { reviewerLogo } = card;
  return (
    <>
      {reviewerLogo ? (
        <Image
          src={reviewerLogo.url}
          alt={reviewerLogo.description || reviewerLogo.title}
          width={reviewerLogo.width}
          height={reviewerLogo.height}
          sizes="(max-width: 768px) 100vw, 768px"
          draggable={false}
          className={`ml-auto mt-20 object-contain`}
        />
      ) : null}
    </>
  );
}

function Signature({ card }: any) {
  const { reviewerName, color } = card;
  return (
    <>
      <p
        className={clsx('mt-5 text-Body-Large font-semibold', {
          'text-[#63F2BC]': color === 'Green',
          'text-[#F4C7FF]': color === 'Purple',
          'text-[#B5DEFF]': color === 'Blue',
        })}
      >
        {reviewerName}
      </p>
    </>
  );
}
