function SvgDownloadIcon() {
    return (
      <div className="col-start-6 row-start-1 self-start justify-self-end rounded-md border-none bg-WHITE/20 p-10px text-WHITE hover:bg-opacity-60">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none">
          <path
            d="M1.66663 11H9.66663M5.66663 1V9M5.66663 9L9.16663 5.5M5.66663 9L2.16663 5.5"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }

  export default SvgDownloadIcon;