import dynamic from 'next/dynamic';
import Image from 'next/image';

import ClientCTAButton from '@/app/components/ui/Buttons/ClientCTAButton';
import { cn } from '@/app/lib/utils/cn';
import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';

const LottieAnimation = dynamic(() => import('./Lottie/LottieAnimation'), { ssr: false });

export default function FeaturedImageComponent({ content }: any) {
  const {
    featuredTwoImageComponent: { title, featuredImage, description, ctaButton, imageLottie, spacingTop, spacingBottom },
  } = content;

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  return (
    <section className={cn('container h-full w-full', paddingTop, paddingBottom)}>
      {/* Desktop */}
      <div className="grid grid-cols-12 overflow-hidden text-WHITE lg:gap-x-10">
        <article className="col-span-6 row-start-1 flex flex-col items-start justify-center md:col-span-full md:row-start-2 md:pt-10">
          <div>
            <h2 className="w-full max-w-[20ch] text-H1 font-bold lg:text-H2 md:text-H1-Mobile">{title}</h2>
            <p className="mt-4 max-w-[60ch] text-balance">{description}</p>
            {ctaButton && (
              <ClientCTAButton buttonData={ctaButton} className="z-10 col-span-full row-start-1 mt-8 inline-block sm:text-xs" />
            )}
          </div>
        </article>

        <article className="card-wrapper-image col-start-7 col-end-13 row-start-1 md:col-span-full">
          <div className="card-content-image relative grid w-full grid-cols-8 gap-x-5 gap-y-15 justify-self-center overflow-clip">
            <div className="col-span-full row-start-1 max-w-[400px]">
              <ItemComponent lottie={imageLottie} />
            </div>
            <Image
              key={featuredImage?.title}
              alt={featuredImage?.description || featuredImage?.title}
              width={featuredImage?.width}
              height={featuredImage?.height}
              src={featuredImage?.url}
              draggable={false}
              className="col-span-full row-start-1 h-full w-full rounded-md object-cover md:aspect-square"
            />
          </div>
        </article>
      </div>
    </section>
  );
}

const ItemComponent = ({ lottie }: any) => {
  return <LottieAnimation animationData={lottie} />;
};
