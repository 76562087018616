import clsx from 'clsx';

import type { ContenfulColorCard } from '@/app/types/contentful/types';

export default function ColorCard({ card, className }: { card: ContenfulColorCard; className: string }) {
  const { color, title, description, headingSize } = card;

  const descriptionClasses: Record<ContenfulColorCard['color'], boolean> = {
    'text-[#63F2BC]': color === 'Green',
    'text-[#B5DEFF]': color === 'Blue',
    'text-[#F4C7FF]': color === 'Purple',
  } as const;

  const headingSizeClasses: Record<ContenfulColorCard['headingSize'], string | null> = {
    Large: 'text-RichText-H2 md:text-RichText-H3',
    Medium: 'text-RichText-H3 md:text-RichText-H4',
    Small: 'text-RichText-H4',
  } as const;

  return (
    <div
      className={clsx(className, 'flex h-full w-full flex-col rounded-lg p-10', description ? 'justify-between' : 'justify-center', {
        'bg-GREEN-950': color === 'Green',
        'bg-BLUE-950': color === 'Blue',
        'bg-PURP-950': color === 'Purple',
        'border border-blue-900/75': color === 'None',
      })}
    >
      {title && (
        <h1
          className={clsx(
            'text-balance text-WHITE/90 sm:mb-12',
            description ? 'mb-8' : 'mb-0',
            headingSizeClasses[headingSize as keyof typeof headingSizeClasses],
            {
              'text-[#63F2BC]': color === 'Green',
              'text-[#B5DEFF]': color === 'Blue',
              'text-[#F4C7FF]': color === 'Purple',
            },
          )}
        >
          {title}
        </h1>
      )}
      {description && (
        <p
          className={clsx(
            'whitespace-pre-wrap text-balance',
            title ? 'text-Body-Large md:text-[1.35rem]' : 'text-[1.6rem] font-semibold',
            'text-WHITE/90',
            descriptionClasses[color as keyof typeof descriptionClasses],
          )}
        >
          {description}
        </p>
      )}
    </div>
  );
}
