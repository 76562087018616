import clsx from 'clsx';

import type { ContenfulColorCard, ContenfulTextWithImageCard, ContenfulTile } from '@/app/types/contentful/types';

import Heading from '../Heading/Heading';
import { SvgLinesComponent } from '../SvgLinesComponent';
import ColorCard from './ColorCard';
import CtaCard from './CtaCard';
import CtaCardLarge from './CtaCardLarge';
import ImageWithCard from './ImageWithCard';
import LocationItem from './LocationItem';
import ProductComparisonTable from './ProductTable';
import RichTextCard from './RichTextCard';
import TextDescriptionCardLarge from './TextDescriptionCardLarge';
import TextDescriptionCardSmall from './TextDescriptionCardSmall';
import Tile from './Tile';

const backgroundTypeClasses: { [key: string]: string | string[] | null } = {
  None: 'bg-BLACK',
  'Slate Blue': 'bg-SLATE_BLUE',
  'Off Black': 'bg-OFF-BLACK',
  'Purple Gradient Top': 'bg-gradient-top-purple bg-top',
  'Blue Gradient Top': 'bg-gradient-top-blue bg-top',
  'Purple Gradient Center': 'bg-gradient-center-purple bg-center',
  'Blue Gradient Center': 'bg-gradient-center-blue bg-center',
  'Purple Gradient Bottom': 'bg-gradient-bottom-purple bg-bottom',
  'Blue Gradient Bottom': 'bg-gradient-bottom-blue bg-bottom',
  'Purple Gradient Top/Bottom': ['bg-gradient-top-purple bg-top', 'bg-gradient-bottom-purple bg-bottom'],
  'Blue Gradient Top/Bottom': ['bg-gradient-top-blue bg-top', 'bg-gradient-bottom-blue bg-bottom'],
} as const;

const spacingClasses = {
  Max: 'py-52 md:py-40 sm:py-32',
  Large: 'py-40',
  Medium: 'py-30',
  Small: 'py-20',
  Default: 'py-10',
  None: 'py-5',
} as const;

export default function HeadingCardsComponent({ content }: any) {
  const {
    headingCardsComponent: { heading, cardsCollection, description, showGridLines, backgroundType, spacing },
  } = content;

  const renderBackground = () => {
    const gradientClass = backgroundTypeClasses[backgroundType];

    if (Array.isArray(gradientClass)) {
      return (
        <>
          <div className={clsx('pointer-events-none absolute -z-10 h-full w-screen bg-cover bg-no-repeat', gradientClass[0])} />
          <div className={clsx('pointer-events-none absolute -z-10 h-full w-screen bg-cover bg-no-repeat', gradientClass[1])} />
        </>
      );
    }

    return gradientClass ? (
      <div className={clsx('pointer-events-none absolute left-0 top-0 -z-20 h-full w-screen bg-cover bg-no-repeat', gradientClass)} />
    ) : null;
  };

  // Group cards by their __typename

  const groupedCards = cardsCollection?.items?.reduce((acc: any, card: ContenfulColorCard | ContenfulTextWithImageCard | ContenfulTile) => {
    if (!acc[card.__typename]) {
      acc[card.__typename] = [];
    }
    acc[card.__typename].push(card);
    return acc;
  }, {});

  return (
    <section className={clsx('relative flex flex-col gap-5 sm:gap-10', spacingClasses[spacing as keyof typeof spacingClasses])}>
      {/* SVG lines */}
      {showGridLines && (
        <div className="absolute left-0 top-0 -z-10 h-full w-full">
          <SvgLinesComponent />
        </div>
      )}
      {/* Background */}
      {renderBackground()}

      {/* HEADING / must be passed as an object with the content as the key for the component to render */}
      <Heading content={{ heading: heading }} />

      {/* Render a CardContainer for each group of cards */}
      {Object.entries(groupedCards).map(([typeName, cards]) => (
        <CardContainer key={typeName} typeName={typeName} cards={cards as any[]} />
      ))}

      {/* Description section */}
      {description && (
        <article className="max-w-[100ch] whitespace-pre-wrap text-WHITE">
          <p className="text-balance">{description}</p>
        </article>
      )}
    </section>
  );
}

// CardContainer component creates a flex or grid layout based on the card type
function CardContainer({ typeName, cards }: { typeName: string; cards: any[] }) {
  // Define flex or grid classes based on card type
  const containerClass =
    typeName === 'ColorCard'
      ? 'grid grid-cols-12 gap-5'
      : typeName === 'TextWithImageCard'
        ? 'grid grid-cols-12 gap-y-40 lg:gap-x-15 gap-x-10 md:gap-y-30 md:gap-x-0'
        : typeName === 'ProductComparisonTable'
          ? 'grid grid-cols-12 gap-y-40 lg:gap-x-15 gap-x-10 md:gap-y-30 md:gap-x-0'
          : typeName === 'TextDescriptionCardSmall'
            ? 'grid grid-cols-12 gap-x-5 gap-y-5 md:gap-y-10 sm:gap-y-12'
            : typeName === 'TextDescriptionCardLarge'
              ? 'grid grid-cols-12 gap-x-5 gap-y-5 md:gap-y-10 sm:gap-x-0'
              : typeName === 'CtaCard'
                ? 'grid grid-cols-auto-fit gap-5 md:gap-y-5 auto-rows-[minmax(175px,auto)]'
                : typeName === 'CtaCardLarge'
                ? 'flex flex-wrap gap-x-5 gap-y-5 place-content-center'
                  : typeName === 'LocationItem'
                    ? 'flex flex-wrap gap-y-15 sm:flex-nowrap sm:flex-col sm:gap-y-0'
                    : typeName === 'Tile'
                      ? 'flex flex-wrap gap-x-5 gap-y-5 place-content-center'
                      : typeName === 'RichTextCard'
                        ? 'flex flex-wrap gap-x-5 gap-y-5 place-content-center'
                        : 'grid-cols-1';

  // Limit the number of cards to 12 for CtaCard type
  const limitedCards = typeName === 'CtaCard' ? cards.slice(0, 12) : cards;

  return (
    <article className={`container ${containerClass as string}`}>
      {limitedCards.map((card, index) => (
        <CardWrapper key={`${card._id}-${index}`} card={card} typeName={typeName} />
      ))}
    </article>
  );
}

// CardWrapper component determines which card component to render and its column span or flex basis
function CardWrapper({ card, typeName }: { card: any; typeName: string }) {
  // Define column span or flex basis based on card type
  const columnSpan =
    typeName === 'ColorCard'
      ? 'col-span-4 md:col-span-full'
      : typeName === 'TextWithImageCard'
        ? 'col-span-6 md:col-span-full'
        : typeName === 'TextDescriptionCardSmall'
          ? 'col-span-3 md-lg:col-span-6 sm:col-span-full'
          : typeName === 'TextDescriptionCardLarge'
            ? 'col-span-6 md:col-span-full'
            : typeName === 'CtaCard'
              ? 'col-span-4 md:col-span-6 sm:col-span-full'
              : typeName === 'CtaCardLarge'
              ? 'flex-[1_1_calc((100%-3rem)/3)] max-w-[550px] min-w-[300px] min-h-[215px] md:max-w-full md:flex-1'
                : typeName === 'ProductComparisonTable'
                  ? 'col-span-full w-full'
                  : typeName === 'LocationItem'
                    ? 'flex-[1_1_250px]'
                    : typeName === 'Tile'
                      ? 'flex-[1_1_calc((100%-3rem)/2)] max-w-[550px] min-h-[215px] min-w-[300px] md:max-w-full md:flex-1'
                      : typeName === 'RichTextCard'
                        ? 'flex-[1_1_calc((100%-3rem)/3)] max-w-[450px] min-w-[440px] md:max-w-full md:min-w-full md:flex-none'
                        : 'col-span-12';

  // Render appropriate card component based on type
  switch (typeName) {
    case 'TextWithImageCard':
      // ImageWithCard component for rendering cards with images
      return <ImageWithCard card={card} className={columnSpan as string} />;
    case 'ColorCard':
      // ColorCard component for rendering color-based cards
      return <ColorCard card={card} className={columnSpan as string} />;
    case 'Tile':
      // Tile component for rendering tiles
      return <Tile card={card} className={columnSpan as string} />;
    case 'TextDescriptionCardSmall':
      // TextDescriptionCardSmall component for rendering text-based cards
      return <TextDescriptionCardSmall card={card} className={columnSpan as string} />;
    case 'TextDescriptionCardLarge':
      // TextDescriptionCardLarge component for rendering text-based cards
      return <TextDescriptionCardLarge card={card} className={columnSpan as string} />;
    case 'LocationItem':
      // LocationItem component for rendering text-based cards
      return <LocationItem card={card} className={columnSpan as string} />;
    case 'CtaCard':
      // CtaCard component for rendering text-based cards
      return <CtaCard card={card} className={columnSpan as string} />;
    case 'CtaCardLarge':
      return <CtaCardLarge card={card} className={columnSpan as string} />;
    case 'RichTextCard':
      return <RichTextCard card={card} className={columnSpan as string} />;
    case 'ProductComparisonTable':
      return <ProductComparisonTable className={columnSpan as string} />;
    default:
      return null;
  }
}
