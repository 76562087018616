import { Linkedin } from 'iconoir-react';
import Image from 'next/image';

import LinkComponent from '../Interactive/Link';

export default function StaffCard({ card }: any) {
  return (
    <div
      className="col-span-3 flex h-full w-full flex-col md:col-span-6"
    >
      {card?.image?.url && (
        <Image
          src={card?.image?.url}
          alt={card?.image.description || card?.image.title}
          width={card?.image.width}
          height={card?.image.height}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 25vw"
          draggable={false}
          className="aspect-[3/4] border-2 border-WHITE/20 w-full rounded-lg bg-[#2E2D3A] object-cover object-center"
        />
      )}

      <h2 className="mt-2 text-Body-Large sm:text-Body">{card.name}</h2>
      <p className="line-clamp-2 text-Body-Large text-WHITE/60 sm:text-xs">{card.role}</p>

      <LinkComponent
        href={card?.linkedinUrl ?? 'https://www.linkedin.com/company/cyware/'}
        className='border-none w-fit'
        target='_blank'
      >
        <Linkedin className="h-6 w-6 md:h-5 md:w-5 mt-2 hover:opacity-70 transition-opacity duration-300 ease-in-out" />
      </LinkComponent>
    </div>
  );
}
