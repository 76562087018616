import clsx from 'clsx';

import BookADemoForm from '@/app/components/ui/Forms/BookADemoForm/BookADemoForm';
import RSABookADemoForm from '@/app/components/ui/Forms/RSA25_BookADemoForm/RSABookADemoForm';
import RSAHappyHourForm from '@/app/components/ui/Forms/RSA25_HappyHourForm/RSAHappyHourForm';
import ThreatBriefingSubscriptionForm from '@/app/components/ui/Forms/ThreatBriefingSubsForm/ThreatBriefingSubscriptionForm';
import { SvgLinesComponent } from '@/app/components/ui/SvgLinesComponent';
import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';

const backgroundTypeClasses: { [key: string]: string | string[] | null } = {
  None: 'bg-BLACK',
  'Slate Blue': 'bg-SLATE_BLUE',
  'Off Black': 'bg-OFF-BLACK',
  'Purple Gradient Top': 'bg-gradient-top-purple bg-top',
  'Blue Gradient Top': 'bg-gradient-top-blue bg-top',
  'Purple Gradient Center': 'bg-gradient-center-purple bg-center',
  'Blue Gradient Center': 'bg-gradient-center-blue bg-center',
  'Purple Gradient Bottom': 'bg-gradient-bottom-purple bg-bottom',
  'Blue Gradient Bottom': 'bg-gradient-bottom-blue bg-bottom',
  'Purple Gradient Top/Bottom': ['bg-gradient-top-purple bg-top', 'bg-gradient-bottom-purple bg-bottom'],
  'Blue Gradient Top/Bottom': ['bg-gradient-top-blue bg-top', 'bg-gradient-bottom-blue bg-bottom'],
} as const;

export default function FormComponent({ content, locationData }: { content: any, locationData: string }) {
  if (!content) {
    return null;
  }

  const {
    formComponent: { label, title, formType, showGridLines, backgroundType, spacingTop, spacingBottom },
  } = content;

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  const renderBackground = () => {
    const gradientClass = backgroundTypeClasses[backgroundType];

    if (Array.isArray(gradientClass)) {
      return (
        <>
          <div className={clsx('pointer-events-none absolute left-0 top-0 -z-10 h-full w-screen bg-cover bg-no-repeat', gradientClass[0])} />
          <div className={clsx('pointer-events-none absolute left-0 top-0 -z-10 h-full w-screen bg-cover bg-no-repeat', gradientClass[1])} />
        </>
      );
    }

    return gradientClass ? (
      <div className={clsx('pointer-events-none absolute left-0 top-0 -z-20 h-full w-screen bg-cover bg-no-repeat', gradientClass)} />
    ) : null;
  };

  const renderForm = () => {
    switch (formType) {
      case 'Book a Demo':
        return <BookADemoForm />;
      case 'Threat Briefing Subscription':
        return <ThreatBriefingSubscriptionForm />;
      case 'RSA 2025 Happy Hour':
        return <RSAHappyHourForm />;
      case 'RSA 2025 Book a Demo':
        return <RSABookADemoForm />;
      default:
        return null;
    }
  };

  return ( 
    <section className={`relative  ${paddingTop} ${paddingBottom}`} id={`${locationData === 'rsa-2025-happy-hour' ? 'rsa-form' : locationData === 'rsa-2025' ? 'book-a-demo' : ''}`}>
      {showGridLines && (
        <div className="absolute left-0 top-0 -z-10 h-full w-full text-WHITE">
          <SvgLinesComponent />
        </div>
      )}
      {renderBackground()}

      <div className="container grid h-full grid-cols-12 place-items-center gap-3">
        {label && (
          <h1 className="col-span-full text-center text-Body-Large font-medium text-GREEN-500 sm:text-Body sm:font-semibold">{label}</h1>
        )}
        {title && (
          <h2 className="col-span-full max-w-[25ch] text-pretty text-center text-H1 text-WHITE md:text-H2 sm:text-balance">{title}</h2>
        )}

        <div className="col-span-full mt-10 w-full sm:mt-0 sm:scale-90 h-full">{renderForm()}</div>
      </div>
    </section>
  );
}
