import clsx from 'clsx';
import Image from 'next/image';

import { ContenfulTextWithImageCard } from '@/app/types/contentful/types';

export default function AboutDescriptionComponent({ content }: any) {
  const { aboutDescriptionComponent: {heading, cardsCollection, description} } = content;
  return (
    <section className="container grid h-full w-full grid-cols-12 gap-y-40 md:gap-y-20 py-20 sm:pb-15">
      <article
        className={clsx(
          'col-span-7 row-start-1 flex flex-col items-start justify-center gap-y-5 text-WHITE md:col-span-full',
          heading?.centerComponent && 'col-span-full items-center justify-self-center',
          heading?.showBackgroundImage && 'col-span-full p-15',
          !heading?.showBackgroundImage && 'lg:col-span-10',
        )}
      >
        <h1 className={clsx('text-Body text-GREEN-500', heading.centerComponent && 'self-start')}>
          {heading.label}
        </h1>
        <p
          className={clsx(
            'text-pretty text-H2 md:text-H1-Mobile sm:text-H3',
            heading.centerComponent && 'max-w-[27ch]',
            heading.showBackgroundImage && 'max-w-[23ch]',
          )}
        >
          {heading.title}
        </p>
        <p className={clsx('mt-15px w-full max-w-[60ch] text-Body', heading.centerComponent && 'max-w-full')}>
          {heading.description}
        </p>
      </article>

      {heading?.showBackgroundImage && (
        <div className="relative col-span-full row-start-1 text-WHITE">
          <article className="absolute inset-0 -z-10 h-full w-full self-center overflow-hidden rounded-lg text-WHITE">
            <Image
              src={heading.backgroundImage.url}
              alt={heading.backgroundImage.title || heading.backgroundImage.description}
              fill
              sizes="100vw"
              draggable={false}
              className="object-cover"
            />
          </article>
        </div>
      )}
      {/* // ! CARDS DESCRIPTION */}
      <article className="col-span-full row-start-2 grid grid-cols-12 gap-10 text-WHITE lg:gap-y-20 md:gap-y-20 sm:gap-x-0">
        {cardsCollection.items.map((card: ContenfulTextWithImageCard, index: number) => {
          return (
            <div key={`${card._id}-${index}`} className="col-span-4 md:col-span-12 w-full space-y-10">
              <Image
                src={card.image.url}
                alt={card.image.description || card.title}
                width={card.image.width}
                height={card.image.height}
                sizes="(max-width: 768px) 100vw, 768px"
                draggable={false}
                className="object-contain"
              />
              <h2 
                className={`max-w-[25ch] text-[1.5rem] font-bold min-h-[100px] md:min-h-fit ${
                  card.underlineTitle && 'underline'
                }`}
              >
                {card.title}
              </h2>
              <p className="text-pretty text-Body-Large text-WHITE/80">{card.description}</p>
            </div>
          );
        })}
      </article>

      <article className="col-span-6 row-start-3 whitespace-pre-wrap text-WHITE lg:col-span-10 md:col-span-full">
        <p className="text-pretty text-Body-Large sm:text-Body">{description}</p>
      </article>
    </section>
  );
}
