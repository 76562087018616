import clsx from 'clsx';
import Image from 'next/image';
import { Link } from 'next-view-transitions';

import SvgDownloadIcon from '@/app/components/ui/SVG Components/SvgDownloadIcon';
import SvgLinkIcon from '@/app/components/ui/SVG Components/SvgLinkIcon';
import { getHref } from '@/app/lib/utils/getHref';

export default function CtaCard({ card, className }: CtaCardProps) {
  const {
    label,
    title,
    description,
    makeTitleLarger,
    ctaAction,
    route,
    slug,
    url,
    backgroundColor,
    accentColor,
    openTheLinkInANewTab,
    image,
  } = card;
  const href: string = getHref(route, slug, url);

  return (
    <Link
      href={href}
      target={openTheLinkInANewTab ? '_blank' : '_self'}
      className={clsx(
        className,
        'group flex h-full flex-col justify-between rounded-md border-[1.5px] p-5 transition-all duration-300 ease-linear hover:bg-opacity-40',
        backgroundColorClasses[backgroundColor as keyof typeof backgroundColorClasses],
        accentColorClasses[accentColor as keyof typeof accentColorClasses],
      )}
    >
      <div className="flex h-full flex-col">
        <div className="mb-auto flex items-center justify-between">
          <div className="flex-grow">
            {label && !image && (
              <p className={clsx('text-xs font-bold', accentColorTextClasses[accentColor as keyof typeof accentColorTextClasses])}>
                {label}
              </p>
            )}
            {title && !image && (
              <p
                className={clsx(
                  'line-clamp-3 text-WHITE',
                  makeTitleLarger ? 'text-H4 sm:text-Body-Large sm:font-bold' : 'lg:text-Body-Large sm:text-Body',
                )}
              >
                {title}
              </p>
            )}
            {image && (
              <Image
                src={image.url}
                alt={image.description || 'Cta Card Image'}
                width={image.width}
                height={image.height}
                className="w-full max-w-[125px]"
              />
            )}
          </div>
          <i className={clsx(image && 'self-start')}>{ctaAction === 'Download' ? <SvgDownloadIcon /> : <SvgLinkIcon />}</i>
        </div>
        {description && <p className="mt-4 line-clamp-2 text-Body text-WHITE/75">{description}</p>}
      </div>
    </Link>
  );
}

type CtaCardProps = {
  card: {
    label: string;
    title: string;
    description: string;
    makeTitleLarger: boolean;
    ctaAction: string;
    route: string;
    slug: string;
    url: string;
    openTheLinkInANewTab: boolean;
    backgroundColor: string;
    accentColor: string;
    image: {
      url: string;
      width: number;
      height: number;
      description: string;
    };
  };
  className?: string;
};

const backgroundColorClasses = {
  None: 'bg-transparent',
  Gray: 'bg-BLACK-100',
  'Slate Blue': 'bg-SLATE_BLUE',
  'Off Black': 'bg-OFF-BLACK',
} as const;

const accentColorClasses = {
  null: 'hover:border-WHITE/50 active:border-WHITE/50',
  None: 'border-2 border-BLACK/50 hover:ring-2 hover:ring-WHITE/50 active:ring-WHITE/50',
  'Default (Light Gray)': 'hover:border-WHITE/50 active:border-WHITE/50',
  Green: 'hover:border-GREEN-500  active:border-GREEN-500 border-GREEN-500/60',
  Blue: 'hover:border-BLUE-400 active:border-BLUE-400 border-BLUE-400/60',
  Purple: 'hover:border-PURP-200 active:border-PURP-200 border-PURP-200/60',
} as const;

const accentColorTextClasses = {
  null: 'text-GREEN-500',
  None: 'text-GREEN-500',
  'Default (Light Gray)': 'text-WHITE',
  Green: 'text-GREEN-500',
  Blue: 'text-BLUE-400',
  Purple: 'text-PURP-200',
} as const;
