import Image from 'next/image';

interface FeaturedLogosComponentProps {
  featuredLogosCollection: {
    items: {
      logo: {
        description: string;
        title: string;
        url: string;
        width: number;
        height: number;
        sys: {
          id: string;
        };
      };
    }[];
  };
}

export default function FeaturedLogosComponent({ featuredLogosCollection }: FeaturedLogosComponentProps) {
  const hasLogos = featuredLogosCollection.items.length > 0;

  return (
    <div className="relative grid h-full w-full grid-cols-12 gap-y-70px" role="region" aria-label="Featured Logos">
      <div className="container z-10 col-span-full grid grid-cols-8 max-w-[1240px] text-WHITE min-2xl:max-w-[1440px]">
        {hasLogos && (
          <ul
            className="col-span-full flex list-none flex-wrap place-content-center place-items-center gap-x-10 gap-y-15"
            aria-label="Partner logos"
          >
            {featuredLogosCollection.items.map(item => {
              const { logo } = item;
              return (
                <li
                  key={logo.sys.id}
                  className="min-w-[200px] max-w-[200px] flex-[1_1_calc((100%-3rem)/3)] md:min-w-[175px] md:max-w-[175px]"
                >
                  <figure className="flex items-center justify-center">
                    <Image
                      alt={logo.description || logo.title}
                      width={logo.width}
                      height={logo.height}
                      src={logo.url}
                      draggable={false}
                      title={logo.title || 'Logo'}
                      loading="lazy"
                    />
                    <figcaption className="sr-only">{logo.title}</figcaption>
                  </figure>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}
