import clsx from 'clsx';
import Image from 'next/image';

import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';
import { ContentfulCard } from '@/app/types/contentful/types';

export default function AboutDescriptionComponent({ content }: any) {
  const {
    investorsComponent: { title, description, investorsCardsCollection, spacingTop, spacingBottom },
  } = content;

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  return (
    <section
      className={`container grid h-full w-full grid-cols-12 place-items-center gap-y-20 md:place-items-start ${paddingTop} ${paddingBottom}`}
    >
      <article className="col-span-full row-start-1 flex flex-col gap-y-4 rounded-lg text-center text-WHITE md:items-start md:text-left">
        {title && <h1 className="text-H1 md:text-H1-Mobile">{title}</h1>}
        {description && <p className="max-w-[55ch] text-Body-Large md:text-Body">{description}</p>}
      </article>

      <article className="col-start-3 col-end-11 row-start-2 grid grid-cols-12 gap-x-1 gap-y-20 text-WHITE md:col-span-full">
        {investorsCardsCollection?.items.map((card: ContentfulCard, index: number) => {
          return (
            <div
              key={`${card._id}-${index}`}
              className={clsx(
                'relative col-span-4 flex flex-col items-center gap-y-5 whitespace-nowrap p-10 text-center uppercase text-WHITE/60 md:p-10 sm:whitespace-normal sm:p-1 sm:text-[0.5rem]',
                {
                  'gradient-border-r': (index + 1) % 3 !== 0 && index !== 2,
                },
              )}
            >
              {card?.image?.url && (
                <Image
                  src={card?.image.url}
                  alt={card?.image.description || card?.title}
                  width={card?.image.width}
                  height={card?.image.height}
                  sizes="(max-width: 500px) 100vw, (max-width: 1200px) 50vw, 25vw"
                  draggable={false}
                  className="aspect-video h-full w-full object-contain sm:px-3"
                />
              )}
              {card.title || (card.label && <h2 className="text-Body sm:text-[0.5rem]">{card.label ?? card.title}</h2>)}
            </div>
          );
        })}
      </article>
    </section>
  );
}
