import clsx from 'clsx';

import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';

import FeaturedLogosComponent from './FeaturedLogosComponent';
import Heading from './Heading/Heading';
import { SvgLinesComponent } from './SvgLinesComponent';

const backgroundTypeClasses: { [key: string]: string | string[] | null } = {
  None: 'bg-BLACK',
  Gray: 'bg-SLATE_BLUE',
  'Off Black': 'bg-OFF-BLACK',
  'Purple Gradient Top': 'bg-gradient-top-purple bg-top',
  'Blue Gradient Top': 'bg-gradient-top-blue bg-top',
  'Purple Gradient Center': 'bg-gradient-center-purple bg-center',
  'Blue Gradient Center': 'bg-gradient-center-blue bg-center',
  'Purple Gradient Bottom': 'bg-gradient-bottom-purple bg-bottom',
  'Blue Gradient Bottom': 'bg-gradient-bottom-blue bg-bottom',
  'Purple Gradient Top/Bottom': ['bg-gradient-top-purple bg-top', 'bg-gradient-bottom-purple bg-bottom'],
  'Blue Gradient Top/Bottom': ['bg-gradient-top-blue bg-top', 'bg-gradient-bottom-blue bg-bottom'],
} as const;

export default function HeadingCardsComponent({ content }: any) {
  const {
    headingLogoComponent: { heading, featuredLogosCollection, backgroundType, showGridLines, spacingTop, spacingBottom },
  } = content;

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  const renderBackground = () => {
    const gradientClass = backgroundTypeClasses[backgroundType];

    if (Array.isArray(gradientClass)) {
      return (
        <>
          <div className={clsx('pointer-events-none absolute -z-10 h-full w-screen bg-cover bg-no-repeat', gradientClass[0])} />
          <div className={clsx('pointer-events-none absolute -z-10 h-full w-screen bg-cover bg-no-repeat', gradientClass[1])} />
        </>
      );
    }

    return gradientClass ? (
      <div className={clsx('pointer-events-none absolute left-0 top-0 -z-20 h-full w-screen bg-cover bg-no-repeat', gradientClass)} />
    ) : null;
  };

  return (
    <section className={`relative flex flex-col gap-5 sm:gap-1 ${paddingTop} ${paddingBottom}`}>
      {/* SVG lines */}
      {showGridLines && (
        <div className="absolute left-0 top-0 -z-10 h-full w-full">
          <SvgLinesComponent />
        </div>
      )}
      {/* Background */}
      {renderBackground()}

      {/* HEADING / must be passed as an object with the content as the key for the component to render */}
      <Heading content={{ heading: heading }} />

      {/* Featured Logos */}
      {featuredLogosCollection && <FeaturedLogosComponent featuredLogosCollection={featuredLogosCollection} />}
    </section>
  );
}
