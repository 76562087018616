import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types';
import clsx from 'clsx';
import { Link } from 'next-view-transitions';
import { ReactNode } from 'react';

type ContenfulRichTextCard = {
  _id: string;
  text: {
    json: Document;
  };
  headingColor: string;
  backgroundColor: string;
  borderColor: string;
};

const headingColorClasses: Record<ContenfulRichTextCard['headingColor'], string | null> = {
  Red: 'text-red-500',
  Yellow: 'text-yellow-500',
  Green: 'text-green-500',
  Blue: 'text-blue-500',
  Purple: 'text-purple-500',
} as const;

export default function RichTextCard({ card, className }: { card: ContenfulRichTextCard; className: string }) {
  const { text, headingColor, backgroundColor, borderColor } = card;

  const options: any = {
    renderNode: {
      [BLOCKS.HEADING_1]: (_node: any, children: any) => (
        <h1
          className={clsx(
            'pb-3 text-[1.75rem] font-bold leading-[1.25] md:pb-1 sm:text-[1.5rem]',
            headingColorClasses[headingColor as keyof typeof headingColorClasses],
          )}
        >
          {children}
        </h1>
      ),
      [BLOCKS.HEADING_2]: (_node: any, children: any) => (
        <h2
          className={clsx(
            'pb-3 text-[1.5rem] font-bold leading-[1.35] text-WHITE/90 md:pb-1 sm:text-[1.25rem]',
            headingColorClasses[headingColor as keyof typeof headingColorClasses],
          )}
        >
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_3]: (_node: any, children: any) => (
        <h3
          className={clsx(
            'pb-3 text-[1.25rem] font-bold leading-[1.35] text-WHITE/90 md:pb-1 sm:text-[1.125rem]',
            headingColorClasses[headingColor as keyof typeof headingColorClasses],
          )}
        >
          {children}
        </h3>
      ),
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => (
        <p className="text-pretty pb-1 text-Body font-normal text-WHITE/75 md:pt-2">{children}</p>
      ),
      [BLOCKS.QUOTE]: (_node: any, children: any) => (
        <blockquote className="text-balance rounded-md bg-slate-700 p-4 py-3 text-Body-Large font-normal text-WHITE">{children}</blockquote>
      ),
      [BLOCKS.HR]: (_node: any, _children: any) => <hr className="border-t-2 border-WHITE/20 pb-5 pt-8" />,
      [INLINES.HYPERLINK]: (node: any, children: any) => {
        const href = node.data.uri;
        return (
          <Link href={href} target="_blank" className="text-BLUE-400 underline">
            {children}
          </Link>
        );
      },
      [BLOCKS.UL_LIST]: (_node: any, children: any) => <ul className="list-disc pl-5 pt-3 text-Body font-normal text-WHITE">{children}</ul>,
      [BLOCKS.OL_LIST]: (_node: any, children: any) => (
        <ol className="list-decimal pl-5 pt-3 text-Body font-normal text-WHITE">{children}</ol>
      ),
      [BLOCKS.LIST_ITEM]: (_node: any, children: any) => <li className="mb-2 marker:text-GREEN-500">{children}</li>,
    },
    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => <strong className="font-bold text-WHITE/90">{text}</strong>,
      [MARKS.ITALIC]: (text: ReactNode) => <em className="italic">{text}</em>,
      [MARKS.UNDERLINE]: (text: ReactNode) => <span className="underline">{text}</span>,
      [MARKS.SUPERSCRIPT]: (text: ReactNode) => <sup className="text-xs">{text}</sup>,
      [MARKS.SUBSCRIPT]: (text: ReactNode) => <sub className="text-xs">{text}</sub>,
      [MARKS.STRIKETHROUGH]: (text: ReactNode) => <span className="line-through">{text}</span>,
    },
  };

  return (
    <div
      className={clsx(className, 'flex w-full flex-col gap-8 whitespace-pre-wrap rounded-lg p-5 text-WHITE/90', {
        'bg-SLATE_BLUE': backgroundColor === 'Slate Blue',
        'bg-OFF-BLACK': backgroundColor === 'Off Black',
        'bg-BLACK-100': backgroundColor === 'Gray',
        'border border-GREEN-500': borderColor === 'Green',
        'border border-PURP': borderColor === 'Purple',
        'border border-BLUE-400': borderColor === 'Blue',
        'border border-slate-500': borderColor === 'Gray',
      })}
    >
      {text.json && (
        <article className="col-start-2 col-end-12 flex animate-fade-in-up flex-col">
          {documentToReactComponents(text.json as Document, options)}
        </article>
      )}
    </div>
  );
}
