import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Image from 'next/image';

import ClientCTAButton from './Buttons/ClientCTAButton';

const LottieAnimation = dynamic(() => import('./Lottie/LottieAnimation'), { ssr: false });
export default function FeaturedImageComponent({ content }: any) {
  const {
    featuredImageComponent: {
      label,
      title,
      description,
      ctaButton,
      featuredImageDesktop,
      featuredImageMobile,
      headingPosition,
      imageType,
      showBackgroundColor,
      imageLottie,
    },
  } = content;

  //* imageType === false -> imageLottie === true
  //* imageType === true -> FeaturedImageDesktop && FeaturedImageMobile === true

  return (
    <section className="container my-35 grid h-full w-full grid-cols-12 md:my-20">
      <article
        className={clsx(
          'z-10 col-span-full grid grid-cols-12 overflow-hidden rounded-lg border-2 border-WHITE/40 text-WHITE',
          showBackgroundColor && 'bg-OFF-BLACK',
        )}
      >
        <div
          className={clsx('z-10 col-span-full row-start-1 flex flex-col items-center justify-center text-center', {
            'row-start-1 self-start py-10': headingPosition === 'Top',
            'row-start-1 self-center': headingPosition === 'Center',
            'row-start-2 self-end py-10': headingPosition === 'Bottom',
          })}
        >
          {label && <h2 className="mb-1 text-Pill text-GREEN-500 sm:mb-4">{label}</h2>}
          <h2
            className={clsx('text-H1 lg:text-H2 md:text-H1-Mobile sm:mb-4', {
              'max-w-[30ch] sm:text-[1.25em]': headingPosition === 'Top' || headingPosition === 'Bottom',
              'max-w-[20ch] text-balance sm:text-H3': headingPosition === 'Center',
              'max-w-[30ch]': headingPosition === 'Center' && !imageType,
            })}
          >
            {title}
          </h2>
          {description && (
            <p className="mt-6 w-full max-w-[55ch] text-balance text-Body-Large md:text-Body sm:max-w-[40ch]">{description}</p>
          )}
          {ctaButton && (
            <ClientCTAButton
              buttonData={ctaButton}
              className="z-10 col-span-full row-start-1 mt-5 sm:text-xs"
            />
          )}
        </div>
        {imageType && (featuredImageDesktop?.url || featuredImageMobile?.url) && (
          <>
            <div className="col-span-full row-start-1 md:hidden">
              <Image
                key={featuredImageDesktop?.title}
                alt={featuredImageDesktop?.description || featuredImageDesktop?.title}
                width={featuredImageDesktop?.width}
                height={featuredImageDesktop?.height}
                src={featuredImageDesktop?.url}
                draggable={false}
                className="object-over h-full w-full"
              />
            </div>
            <div className="col-span-full row-start-1 hidden md:block">
              <Image
                key={featuredImageMobile?.title}
                alt={featuredImageMobile?.description || featuredImageMobile?.title}
                width={featuredImageMobile?.width}
                height={featuredImageMobile?.height}
                src={featuredImageMobile?.url}
                draggable={false}
                className="h-full w-full object-cover"
              />
            </div>
          </>
        )}
        {!imageType && imageLottie && (
          <div
            className={clsx('col-span-full row-start-1', {
              'row-start-2': headingPosition === 'Top',
              'row-start-1': headingPosition === 'Center' || headingPosition === 'Bottom',
            })}
          >
            <ItemComponent lottie={imageLottie} />
          </div>
        )}
      </article>
    </section>
  );
}
const ItemComponent = ({ lottie }: any) => {
  return <LottieAnimation animationData={lottie} />;
};
