const features = [
  { name: 'Dashboard', intel: 'Out-of-the-Box Dashboard, Sharing of Dashboard, Feeds ROI', lite: 'Out-of-the-Box Dashboard, Sharing of Dashboard, -', spoke: 'Out-of-the-Box Dashboard - Limited set of widgets, -, -' },
  { name: 'Reports', intel: 'Custom Reporting Capabilities', lite: 'Custom Reporting Capabilities', spoke: 'Custom Reporting Capabilities Max. 2 reports' },
  { name: 'Intel Collection', intel: 'Customizable to Your Organization\'s Unique Needs\nThreat Data - All SDO support (STIX 1.x, 2.0 and 2.1 support)\nThreat Bulletin - Create & View\nUnstructured Intel - RSS\nUnstructured Intel - Threat Mailbox\nUnstructured Intel - Twitter Module\nQuick Add Intel, Import Intel\nWebscraper, Webhooks\nManual Intel Ingestion via text, URL, file import', lite: 'Upper limit to 50K Objects / Day\nThreat Data - All SDO support (STIX 1.x, 2.0 and 2.1 support)\nThreat Bulletin - View\nUnstructured Intel - RSS\nUnstructured Intel - Threat Mailbox\n-\nQuick Add Intel, Import Intel\nWebscraper\nManual Intel Ingestion via text, URL, file import', spoke: 'Upper limit to 10k Objects / Day\nThreat Data - All SDO support (STIX 2.1 support for ingestion)\n-\n-\nThreat Mailbox (1 mail account only)\n-\nQuick Add Intel, Import Intel\n-\nManual Intel Ingestion via text, URL, file import' },
  { name: 'Inbox Capabilities', intel: 'Customizable to Your Organization\'s Unique Needs', lite: 'Sharing is allowed to any 3 TAXII Feed Providers', spoke: 'Sharing is allowed to any 1 TAXII Feed Providers' },
  { name: 'Indicators Allowed (Allowlist)', intel: 'All', lite: 'All', spoke: '-' },
  { name: 'Intel Scoring', intel: 'Confidence Score Engine', lite: 'Confidence Score Engine', spoke: '-' },
  { name: 'Rules Engine', intel: 'Build your own rule - Unlimited', lite: 'Build your own rule - Max of 10 active rules', spoke: 'Build your own rule - Max of 2 active rules' },
  { name: 'Attack Navigator', intel: 'Full Version', lite: 'Full Version', spoke: '-' },
  { name: 'Threat Investigation', intel: 'Full Version', lite: '-', spoke: '-' },
  { name: 'Dissemination - Detailed Submission', intel: 'Customizable to Your Organization\'s Unique Needs', lite: 'Inbox to any 3 TAXI feed providers', spoke: 'Inbox to any 1 TAXI feed provider' },
  { name: 'Analyst Workbench', intel: 'Fang-Defang\nSTIX Conversion\nEncode-Decode 64\nCVSS Calculator\nNetwork Utilities', lite: '-', spoke: '-' },
  { name: 'Global Tasks', intel: 'Create and Action tasks', lite: '-', spoke: '-' },
  { name: 'My Org', intel: 'Indicators Allowed\nWatchlist', lite: 'Indicators Allowed\nWatchlist', spoke: '-' },
  { name: 'Authentication', intel: 'Username/Password\nLDAP\n2 FA enabled - Email/TOTP', lite: 'Username/Password\n-\n2 FA enabled - Email/TOTP', spoke: 'Username/Password\n-\n2 FA enabled - TOTP' },
  { name: 'Feed Integrations', intel: 'All', lite: 'All', spoke: 'All' },
  { name: 'STIX and ISAC Integration', intel: 'All', lite: 'All', spoke: 'Maximum 5 STIX/ISAC sources' },
  { name: 'Feed Enrichment', intel: 'All', lite: 'All', spoke: '-' },
  { name: 'Tool Integration - SIEM', intel: 'All', lite: 'All', spoke: 'All' },
  { name: 'Tool Integration - SOAR Solution', intel: 'All', lite: 'All', spoke: 'All' },
  { name: 'Tool Integration - Network Security', intel: 'All', lite: 'All', spoke: 'All' },
  { name: 'Tool Integration - Endpoint Detection Response', intel: 'All', lite: 'All', spoke: 'All' },
  { name: 'Console Status', intel: 'Fully Enabled', lite: '-', spoke: '-' },
  { name: 'SSO Enablement', intel: 'Yes', lite: '-', spoke: '-' },
  { name: 'Hub and Spoke', intel: 'Yes', lite: '-', spoke: '-' },
  { name: 'Open API', intel: 'Yes', lite: 'Yes', spoke: 'Available in Select Configurations Only' },
  { name: 'Users', intel: '-', lite: '-', spoke: '2' },
  { name: 'Administration', intel: 'User Management\nLicense Management\nCustom Entities Management\nAudit Log Management\nTag Management\nSubscribers\nConfiguration', lite: 'Audit Log Management\nTag Management\nUser Management\nLicense Management\nConfiguration (without Custom Score)', spoke: 'User Management\nConfiguration (without Custom Score)' },
];

const ProductComparisonTable = ({ className }: { className: string }) => {
  return (
    <div className={`overflow-hidden border-2 border-WHITE/20 rounded-md h-[80dvh] mb-10 relative ${className}`}>
      <div className="overflow-x-auto overflow-y-scroll h-full overscroll-contain">
        <table className="w-full divide-y divide-BLACK-100 bg-SLATE_BLUE">
          <thead className="bg-BLACK-100 sticky top-0 z-10">
            <tr>
              <th className="px-3 py-2 md:px-6 md:py-3 text-left text-md md:text-xs font-medium text-WHITE uppercase tracking-wider min-w-[150px]">Features/Capabilities</th>
              <th className="px-3 py-2 md:px-6 md:py-3 text-left text-md md:text-xs font-medium text-WHITE uppercase tracking-wider min-w-[150px]">Intel Exchange</th>
              <th className="px-3 py-2 md:px-6 md:py-3 text-left text-md md:text-xs font-medium text-WHITE uppercase tracking-wider min-w-[150px]">Intel Exchange Lite</th>
              <th className="px-3 py-2 md:px-6 md:py-3 text-left text-md md:text-xs font-medium text-WHITE uppercase tracking-wider min-w-[150px]">Intel Exchange Spoke</th>
            </tr>
          </thead>
          <tbody className="bg-SLATE_BLUE divide-y divide-BLACK-100">
            {features.map((feature, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-BLACK-100 bg-opacity-50' : 'bg-SLATE_BLUE'}>
                <td className="px-3 py-2 md:px-6 md:py-4 text-md md:text-xs font-medium text-WHITE">{feature.name}</td>
                <td className="px-3 py-2 md:px-6 md:py-4 whitespace-pre-line text-md md:text-xs text-GRAY">{feature.intel}</td>
                <td className="px-3 py-2 md:px-6 md:py-4 whitespace-pre-line text-md md:text-xs text-GRAY">{feature.lite}</td>
                <td className="px-3 py-2 md:px-6 md:py-4 whitespace-pre-line text-md md:text-xs text-GRAY">{feature.spoke}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductComparisonTable;