import { Document } from '@contentful/rich-text-types';
import { BrightStar, WarningTriangleSolid } from 'iconoir-react';

import ComparisonCard from '@/app/components/ui/ComparisonComponents/ComparisonCard';
import Heading from '@/app/components/ui/Heading/Heading';
import { SvgLinesComponent } from '@/app/components/ui/SvgLinesComponent';
import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';
import renderBackground from '@/app/lib/utils/renderBackground';
import { HeadingComponentData } from '@/app/types/contentful/types';

interface ComparisonComponentProps {
  content: {
    __typename: string;
    sys: {
      id: string;
    };
    comparisonComponent: {
      heading: HeadingComponentData;
      ourProductTitle: string;
      competitorProductTitle: string;
      showGridLines: boolean;
      backgroundType: string;
      spacingTop: number;
      spacingBottom: number;
      comparisonContentCollection: {
        items: {
          _id: string;
          comparisonItemHeading: string;
          comparisonCardsCollection: {
            items: {
              _id: string;
              cardType: string;
              cardTextContent: {
                json: Document;
              };
            }[];
          };
        }[];
      };
    };
  };
}

export default function ComparisonComponent({ content }: ComparisonComponentProps) {
  const {
    comparisonComponent: {
      heading,
      ourProductTitle,
      competitorProductTitle,
      showGridLines,
      backgroundType,
      spacingTop,
      spacingBottom,
      comparisonContentCollection,
    },
  } = content;

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  return (
    <section className="relative col-span-full flex flex-col gap-4">
      {showGridLines && (
        <div className="absolute left-0 top-0 -z-10 h-full w-full">
          <SvgLinesComponent />
        </div>
      )}

      {renderBackground(backgroundType as string)}

      <article className={`${paddingTop} ${paddingBottom}`}>
        <Heading content={{ heading: heading as HeadingComponentData }} />

        <section className="flex w-full flex-wrap justify-center gap-x-5 gap-y-4">
          <span className="flex items-center gap-x-2 rounded-lg bg-GREEN/40 px-4 py-2 shadow-[0_0_5px_2px_rgba(74,222,128,0.6)] ring-1 ring-inset ring-green-400 backdrop-blur-sm">
            <BrightStar className="text-GREEN-500 drop-shadow-[0_0_1px_rgba(255,255,255,0.8)]" />
            <h2 className="block text-Body text-WHITE">{ourProductTitle}</h2>
          </span>
          <span className="flex items-center gap-x-2 rounded-lg bg-DARK-GRAY/40 px-4 py-2 ring-1 ring-inset ring-red-500 backdrop-blur-sm">
            <WarningTriangleSolid className="text-red-500" />
            <h2 className="block text-Body text-WHITE">{competitorProductTitle}</h2>
          </span>
        </section>

        <section className="mt-10 min-sm:container">
          <article className="w-full rounded-lg border border-GREEN-500/35 bg-SLATE_BLUE/50 py-5 shadow-[0_0_5px_2px_rgba(74,222,128,0.2)] backdrop-blur-sm sm:bg-none sm:shadow-none sm:border-none min-md:px-8">
            {comparisonContentCollection.items.map(item => (
              <div key={item._id} className="flex flex-col">
                {item.comparisonItemHeading && (
                  <h3 className="border-b border-GREEN-500 py-4 pl-2 text-H3 text-WHITE sm:text-center">{item.comparisonItemHeading}</h3>
                )}
                <section className="my-4 flex flex-col gap-y-5 pt-4 sm:px-8">
                  {item.comparisonCardsCollection.items.map(card => (
                    <ComparisonCard key={card._id} cardType={card.cardType} cardTextContent={card.cardTextContent} />
                  ))}
                </section>
              </div>
            ))}
          </article>
        </section>
      </article>
    </section>
  );
}
