import Image from 'next/image';

import type { ContenfulTextWithImageCard } from '@/app/types/contentful/types';

export default function ImageWithCard({ card, className }: { card: ContenfulTextWithImageCard; className: string }) {
  const { title, description, image, underlineTitle } = card;
  return (
    <div className={`${className} w-full grid grid-rows-[75px_1fr] gap-y-9 text-WHITE`}>
      {image?.url && (
        <Image
          src={image.url}
          alt={image.description || title}
          width={image.width}
          height={image.height}
          sizes="(max-width: 768px) 100vw, 768px"
          draggable={false}
          className="object-contain object-center"
        />
      )}
      <div className="flex flex-col gap-y-1">
        {title && <h2 className={`text-Body-Large pb-5 ${underlineTitle ? 'underline' : ''}`}>{title}</h2>}
        {description && <p className="text-Body-Large max-w-[50ch] text-balance">{description}</p>}
      </div>
    </div>
  );
}
