import {
  ABOUT_DESCRIPTION_COMPONENT_FIELDS,
  BLOG_LISTING_COMPONENT_FIELDS,
  BUTTON_FIELDS,
  CARDS_COMPONENT_FIELDS,
  COMPARISON_COMPONENT_FIELDS,
  DETAIL_PAGE_FIELDS,
  FAQ_COMPONENT_FIELDS,
  FEATURED_IMAGE_COMPONENT_FIELDS,
  FEATURED_TWO_IMAGE_COMPONENT_FIELDS,
  FORM_COMPONENT_FIELDS,
  HEADING_CARDS_COMPONENT_FIELDS,
  HEADING_FIELDS,
  HEADING_LISTING_COMPONENT_FIELDS,
  HEADING_LOGO_COMPONENT_FIELDS,
  HERO_COMPONENT_SINGLE_COLUMN_FIELDS,
  HERO_COMPONENT_TWO_COLUMN_FIELDS,
  IMAGE_FIELDS,
  INVESTORS_COMPONENT_FIELDS,
  LINK_FIELDS,
  LISTING_COMPONENT_FIELDS,
  LISTING_TEXT_COMPONENT_FIELDS,
  LOGO_COMPONENT_FIELDS,
  MARQUEE_COMPONENT_FIELDS,
  NEWS_AND_PRESS_COMPONENT_FIELDS,
  NEWS_PRESS_CARDS_DETAIL_FIELDS,
  NEWS_PRESS_CARDS_LISTING_FIELDS,
  PRODUCT_COMPONENT_FIELDS,
  REVIEWS_COMPONENT_FIELDS,
  RICH_TEXT_COMPONENT_FIELDS,
  RICH_TEXT_FIELDS,
  STAFF_COMPONENT_FIELDS,
  STICKY_COMPONENT_FIELDS as SINGLE_COLUMN_SECTIONS_COMPONENT_FIELDS,
  THREAT_LISTING_COMPONENT_FIELDS,
  TWO_COLUMN_COMPONENT_FIELDS,
  TWO_COLUMN_STICKY_COMPONENT_FIELDS,
  USE_CASES_CARD_COMPONENT_FIELDS,
} from '@/app/lib/contentful/queries/QueryFields';

export default async function fetchGraphQL(
  query: string,
  variables: Record<string, any> = {},
  preview: boolean = false,
  _tags: string[] = [],
) {
  try {
    const environment = process.env.CONTENTFUL_ENVIRONMENT || 'master';
    // const environment = 'development';
    const response = await fetch(
      `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/environments/${environment}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${preview ? process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN : process.env.CONTENTFUL_ACCESS_TOKEN}`,
        },
        body: JSON.stringify({ query, variables }),
        next: { revalidate: 7200 },
      },
    );

    const responseData = await response.json();
    // console.log('Raw response:', JSON.stringify(responseData, null, 2)); // Log the raw response

    if (responseData.errors) {
      console.error('GraphQL Errors:', responseData.errors);
      throw new Error('GraphQL query failed');
    }

    return responseData.data || null; // Return null if data is undefined
  } catch (error) {
    console.error('Error fetching data from Contentful:', error);
    throw error;
  }
}

function extractAllSlugPages(fetchResponse: any): any[] {
  return fetchResponse.detailPageCollection.items;
}

function extractFooter(fetchResponse: any): any {
  return fetchResponse.footerComponent;
}

function extractNavbar(fetchResponse: any): any[] {
  return fetchResponse?.navbar;
}

async function getComponentData(componentType: string, componentId: string, isDraftMode: boolean) {
  const componentFields: { [key: string]: string } = {
    AboutDescriptionComponent: ABOUT_DESCRIPTION_COMPONENT_FIELDS,
    BlogListingComponent: BLOG_LISTING_COMPONENT_FIELDS,
    ComparisonComponent: COMPARISON_COMPONENT_FIELDS,
    CardsComponent: CARDS_COMPONENT_FIELDS,
    FaqComponent: FAQ_COMPONENT_FIELDS,
    FeaturedImageComponent: FEATURED_IMAGE_COMPONENT_FIELDS,
    FeaturedTwoImageComponent: FEATURED_TWO_IMAGE_COMPONENT_FIELDS,
    FormComponent: FORM_COMPONENT_FIELDS,
    Heading: HEADING_FIELDS,
    HeadingCardsComponent: HEADING_CARDS_COMPONENT_FIELDS,
    HeadingListingComponent: HEADING_LISTING_COMPONENT_FIELDS,
    HeadingLogoComponent: HEADING_LOGO_COMPONENT_FIELDS,
    HeroComponentSingleColumn: HERO_COMPONENT_SINGLE_COLUMN_FIELDS,
    HeroComponentTwoColumn: HERO_COMPONENT_TWO_COLUMN_FIELDS,
    InvestorsComponent: INVESTORS_COMPONENT_FIELDS,
    ListingComponent: LISTING_COMPONENT_FIELDS,
    ListingTextComponent: LISTING_TEXT_COMPONENT_FIELDS,
    LogoComponent: LOGO_COMPONENT_FIELDS,
    MarqueeComponent: MARQUEE_COMPONENT_FIELDS,
    NewsPressListingsComponent: NEWS_AND_PRESS_COMPONENT_FIELDS,
    ProductComponent: PRODUCT_COMPONENT_FIELDS,
    ReviewAppraisalComponent: REVIEWS_COMPONENT_FIELDS,
    RichTextComponent: RICH_TEXT_COMPONENT_FIELDS,
    StaffComponent: STAFF_COMPONENT_FIELDS,
    StickyComponent: SINGLE_COLUMN_SECTIONS_COMPONENT_FIELDS,
    ThreatListingComponent: THREAT_LISTING_COMPONENT_FIELDS,
    TwoColumnComponent: TWO_COLUMN_COMPONENT_FIELDS,
    TwoColumnStickyComponent: TWO_COLUMN_STICKY_COMPONENT_FIELDS,
    UseCasesCardsComponent: USE_CASES_CARD_COMPONENT_FIELDS,
    DetailPage: DETAIL_PAGE_FIELDS,
  };

  const variables = {
    id: componentId,
    preview: isDraftMode,
  };

  const contentfulComponentType = componentType.charAt(0).toLowerCase() + componentType.slice(1);

  const query = `
    query GetComponentData($id: String!, $preview: Boolean!) {
      ${contentfulComponentType}(id: $id, preview: $preview) {
        ${componentFields[componentType]}
      }
    }
  `;

  try {
    const data = await fetchGraphQL(query, variables, isDraftMode);
    return data;
  } catch (error) {
    console.error('Error fetching component data:', error);
    throw error;
  }
}

export async function getPageStructure(route: string, isDraftMode = false) {
  const query = `
    query GetPageStructure($route: String!, $preview: Boolean!) {
      standardPageCollection(where: { route: $route }, preview: $preview, limit: 1) {
        items {
          ... on StandardPage {
            _id
            __typename
            sys {
              id
            }
            title
            route
            seoTitle
            seoDescription
            seoKeywords
            seoImage {
              title
              description
              url
            }
            sectionsCollection(limit: 10) {
              items {
                __typename
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
  `;

  const variables = {
    route,
    preview: isDraftMode,
  };

  try {
    const data = await fetchGraphQL(query, variables, isDraftMode);
    return data.standardPageCollection.items[0] || null;
  } catch (error) {
    console.error('Error fetching page structure:', error);
    throw error;
  }
}

// ! Standard Page Queries

export async function getStandardPage(route: string, isDraftMode = false) {
  try {
    const pageStructure = await getPageStructure(route, isDraftMode);

    // Early return with null if pageStructure is null
    if (!pageStructure) {
      console.warn(`Page structure not found for route: ${route}`);
      return { data: null };
    }

    if (!pageStructure.sectionsCollection) {
      console.warn(`No sections collection found for route: ${route}`);
      return { data: pageStructure };
    }

    // Safely handle empty or null items array
    const items = pageStructure.sectionsCollection.items || [];

    const componentPromises = items.map(async (section: any) => {
      if (!section || !section.__typename || !section.sys || !section.sys.id) {
        console.warn('Invalid section data:', section);
        return null;
      }

      try {
        const componentData = await getComponentData(section.__typename, section.sys.id, isDraftMode);
        return {
          ...section,
          ...componentData,
        };
      } catch (componentError) {
        console.error(`Error fetching component data for ${section.__typename}:`, componentError);
        return section; // Return original section without additional data
      }
    });

    const resolvedComponents = await Promise.all(componentPromises);
    const filteredComponents = resolvedComponents.filter(Boolean);

    return {
      data: {
        ...pageStructure,
        sectionsCollection: {
          items: filteredComponents,
        },
      },
    };
  } catch (error) {
    console.error(`Error fetching standard page for route ${route}:`, error);
    return { data: null };
  }
}

export async function getSlugPageStructure(slug: string, isDraftMode = false) {
  const decodedSlug = decodeURIComponent(slug);

  const query = `
    query GetPageStructure($slug: String!, $preview: Boolean!) {
      detailPageCollection(where: { slug: $slug }, preview: $preview, limit: 1) {
        items {
          ... on DetailPage {
            _id
            sys {
              id
            }
            slug
            route
            seoTitle
            seoDescription
            seoKeywords
            seoImage {
              title
              description
              url
            }
            sectionsCollection(limit: 10) {
              items {
                __typename
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
  `;

  const variables = {
    slug: decodedSlug,
    preview: isDraftMode,
  };

  try {
    const data = await fetchGraphQL(query, variables, isDraftMode);
    return data?.detailPageCollection?.items?.[0] || null;
  } catch (error) {
    console.error(`Error fetching page structure for slug ${slug}:`, error);
    return null;
  }
}

// ! Detail Page Queries

export async function getDetailPage(slug: string, isDraftMode = false) {
  try {
    const slugPageStructure = await getSlugPageStructure(slug, isDraftMode);

    // Early return with null if slugPageStructure is null
    if (!slugPageStructure) {
      console.warn(`Page structure not found for slugs: ${slug}`);
      return { data: null };
    }

    if (!slugPageStructure.sectionsCollection) {
      console.warn(`No sections collection found for slug: ${slug}`);
      return { data: slugPageStructure };
    }

    // Safely handle empty or null items array
    const items = slugPageStructure.sectionsCollection.items || [];

    const componentPromises = items.map(async (section: any) => {
      if (!section || !section.__typename || !section.sys || !section.sys.id) {
        console.warn('Invalid section data:', section);
        return null;
      }

      try {
        const componentData = await getComponentData(section.__typename, section.sys.id, isDraftMode);
        return {
          ...section,
          ...componentData,
        };
      } catch (componentError) {
        console.error(`Error fetching component data for ${section.__typename}:`, componentError);
        return section; // Return original section without additional data
      }
    });

    const resolvedComponents = await Promise.all(componentPromises);
    const filteredComponents = resolvedComponents.filter(Boolean);

    return {
      data: {
        ...slugPageStructure,
        sectionsCollection: {
          items: filteredComponents,
        },
      },
    };
  } catch (error) {
    console.error(`Error fetching detail page for slug ${slug}:`, error);
    return { data: null };
  }
}

export async function getAllDetailPagesByRoute(route: string, isDraftMode = false) {
  const query = `
    query GetDetailPages($route: String!, $preview: Boolean!) {
      detailPageCollection(where: { route: $route}, preview: $preview, limit: 1000) {
        items {
          ... on DetailPage {
            title
            route
            slug
          }
        }
      }
    }
  `;

  const variables = {
    route: route,
    preview: isDraftMode,
  };

  try {
    const data = await fetchGraphQL(query, variables, isDraftMode);
    return extractAllSlugPages(data);
  } catch (error) {
    console.error('Error fetching page structure:', error);
    throw error;
  }
}

export async function getAllPages() {
  const query = `
    query GetAllPages {
      standardPageCollection(limit: 100) {
        items {
          ... on StandardPage {
            title
            route
            sys {
              publishedAt
            }
          }
        }
      }
    }
  `;

  const variables = {};

  try {
    const data = await fetchGraphQL(query, variables, false);
    // console.log('getAllPages raw data:', JSON.stringify(data, null, 2));
    if (!data || !data.standardPageCollection || !Array.isArray(data.standardPageCollection.items)) {
      console.error('Unexpected data structure from getAllPages query');
      return [];
    }
    return data.standardPageCollection.items;
  } catch (error) {
    console.error('Error fetching page structure:', error);
    return [];
  }
}

export async function getAllDetailPages() {
  const query = `
    query GetAllDetailPages {
      detailPageCollection {
        items {
          ... on DetailPage {
            title
            route
            slug
            sys {
              publishedAt
            }
          }
        }
      }
    }
  `;

  const variables = {};

  try {
    const data = await fetchGraphQL(query, variables, false);
    // console.log('getAllDetailPages raw data:', JSON.stringify(data, null, 2));
    if (!data || !data.detailPageCollection || !Array.isArray(data.detailPageCollection.items)) {
      console.error('Unexpected data structure from getAllDetailPages query');
      return [];
    }
    return data.detailPageCollection.items;
  } catch (error) {
    console.error('Error fetching page structure:', error);
    return [];
  }
}

// ! Blog Page Queries

// Get All Blog Pages
export async function getAllBlogPages(isDraftMode = false) {
  const query = `
    query GetAllBlogPages($preview: Boolean!) {
      blogPageCollection(preview: $preview, limit: 100, order: date_DESC) {
        items {
          _id
          title
          tags
          authorData {
            authorName
          }
          date
          route
          slug
          sys {
            id
            publishedAt
          }
          coverImage {
            ${IMAGE_FIELDS}
          }
          seoTitle
          seoDescription
          seoKeywords
          seoImage {
            ${IMAGE_FIELDS}
          }
        }
      }
    }
  `;

  const variables = { preview: isDraftMode };

  try {
    const data = await fetchGraphQL(query, variables, isDraftMode);
    // console.log('getAllBlogPages raw data:', JSON.stringify(data, null, 2));
    if (!data || !data.blogPageCollection || !Array.isArray(data.blogPageCollection.items)) {
      console.error('Unexpected data structure from getAllBlogPages query');
      return [];
    }
    return data.blogPageCollection.items;
  } catch (error) {
    console.error('Error fetching blog pages:', error);
    return [];
  }
}

// ? Get Blog Page by Slug

export async function getBlogPage(slug: string, isDraftMode = false) {
  const query = `
    query GetBlogPage($slug: String!) {
      blogPageCollection(where: { slug: $slug }, limit: 1) {
        items {
          ... on BlogPage {
            _id
            title
            authorData {
              authorName
              authorDesignation
              authorAvatar {
                ${IMAGE_FIELDS}
              }
              authorBio
              authorSocialLink
            }
            showAuthorData
            coverImage {
              ${IMAGE_FIELDS}
            }
            bodyContent
            body {
              ${RICH_TEXT_FIELDS}
            }
            tags
            date
            route
            slug
            sys {
              publishedAt
            } 
            seoTitle
            seoDescription
            seoImage {
              ${IMAGE_FIELDS}
            }
            seoKeywords
          }
        }
      }
    }
  `;

  const variables = { slug, preview: isDraftMode };

  try {
    const data = await fetchGraphQL(query, variables, isDraftMode);
    return data?.blogPageCollection?.items?.[0] || null;
  } catch (error) {
    console.error(`Error fetching blog page for slug ${slug}:`, error);
    return null;
  }
}

// ! Threat Briefing Page Queries

export async function getAllThreatBriefingPages(isDraftMode = false, limit = 1000) {
  const query = `
    query GetAllThreatBriefingPages($preview: Boolean!, $limit: Int!) {
      threatBriefingPageCollection(preview: $preview, limit: $limit, order: date_DESC) {
        items {
          sys {
            id
          }
          route
          slug
          sys {
            publishedAt
          }
        }
      }
    }
  `;

  const variables = { preview: isDraftMode, limit };

  try {
    const data = await fetchGraphQL(query, variables, isDraftMode);
    if (!data || !data.threatBriefingPageCollection || !Array.isArray(data.threatBriefingPageCollection.items)) {
      console.error('Unexpected data structure from getAllThreatBriefingPages query');
      return [];
    }
    return data.threatBriefingPageCollection.items;
  } catch (error) {
    console.error('Error fetching threat briefing pages:', error);
    return [];
  }
}

export async function getThreatBriefingByCategory(category: string, isDraftMode = false) {
  const query = `
    query GetThreatBriefingByCategory($category: String, $preview: Boolean!) {
      threatBriefingPageCollection(where: { category: $category }, preview: $preview, limit: 25, order: date_DESC) {
        items {
          sys {
            id
          }
          title
          coverImage {
            ${IMAGE_FIELDS}
          }
          date
          category
          route
          slug
          sys {
            publishedAt
          }
          seoTitle
          seoDescription
          seoKeywords
          seoImage {
            ${IMAGE_FIELDS}
          }
        }
      }
    }
  `;

  const variables = { category, preview: isDraftMode };

  try {
    const data = await fetchGraphQL(query, variables, isDraftMode);
    if (!data || !data.threatBriefingPageCollection || !Array.isArray(data.threatBriefingPageCollection.items)) {
      console.error('Unexpected data structure from getThreatBriefingByCategory query');
      return [];
    }
    return data.threatBriefingPageCollection.items;
  } catch (error) {
    console.error('Error fetching threat briefing page by category:', error);
    return [];
  }
}

export async function getThreatBriefingBySlug(slug: string, isDraftMode = false) {
  const decodedSlug = decodeURIComponent(slug);
  const query = `
  
    query GetThreatBriefingBySlug($slug: String!, $preview: Boolean!) {
      threatBriefingPageCollection(where: { slug: $slug }, preview: $preview, limit: 1) {
        items {
          sys {
            id
          }
          title
          coverImage {
            ${IMAGE_FIELDS}
          }
          body {
            ${RICH_TEXT_FIELDS}
          }
          bodyContent
          date
          category
          route
          slug
          sys {
            publishedAt
          }
          seoTitle
          seoDescription
          seoKeywords
          seoImage {
            ${IMAGE_FIELDS}
          }
        }
      }
    }
  `;

  const variables = { slug: decodedSlug, preview: isDraftMode };

  try {
    const data = await fetchGraphQL(query, variables, isDraftMode);
    if (!data || !data.threatBriefingPageCollection || !Array.isArray(data.threatBriefingPageCollection.items)) {
      console.warn(`No threat briefing found for slug ${slug}`);
      return null;
    }
    return data.threatBriefingPageCollection.items[0] || null;
  } catch (error) {
    console.error(`Error fetching threat briefing page for slug ${slug}:`, error);
    return null;
  }
}

// ! GLOBAL QUERIES

export async function getNavbar(id: string) {
  const entry = await fetchGraphQL(
    `
    query {
        navbar(id: "${id}") {
          headband {
            ... on HeadbandItem {
              title
              ctaLabel
              route
              slug
              externalUrl
            }
          }
          showHeadband
          logo {
            ${IMAGE_FIELDS}
          }
          buttonsCollection(limit:2) {
            items {
              ${BUTTON_FIELDS}
            }
          }
          navItemsCollection(limit:7) {
              items {
                  ... on NavItem {
                      _id
                      title
                      link
                      dropdown
                      subNav {
                          tag
                          specialLink
                          specialLinkLabel
                          specialLinkUrl
                          menuGroupCollection(limit:4) {
                              items {
                                  ... on MenuGroup {
                                      menuGroupItemCollection(limit: 6) {
                                          items {
                                              ... on NavItem {
                                                _id
                                                title
                                                link
                                                dropdown
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
        }
      }
    `,
  );
  return extractNavbar(entry);
}

export async function getFooter(id: string) {
  const entry = await fetchGraphQL(`
    query {
      footerComponent(id: "${id}") {
        title
        description
        footerNavigationLinksTitle
        footerSocialLinksTitle
        copyrightNotice
        gradientType
        ctaButton {
          ${BUTTON_FIELDS}
        }
        footerLogo {
          ${IMAGE_FIELDS}
        }
        footerNavigationLinksCollection {
          items {
            _id
            ${LINK_FIELDS}
          }
        }
        footerSocialLinksCollection {
          items {
            _id
            ${LINK_FIELDS}
          }
        }
        footerPoliciesLinksCollection {
          items {
            _id
            ${LINK_FIELDS}
          }
        }
      } 
    }
  `);
  return extractFooter(entry);
}

// ! FORM QUERIES

export async function getFormByType(formType: string) {
  const query = `
    query GetFormByType($formType: String!) {
      formComponentCollection(where: { formType: $formType }, limit: 1) {
        items {
          ${FORM_COMPONENT_FIELDS}
        }
      }
    }
  `;

  const variables = { formType };

  try {
    const data = await fetchGraphQL(query, variables, false);
    return data?.formComponentCollection?.items?.[0] || null;
  } catch (error) {
    console.error(`Error fetching form by type ${formType}:`, error);
    return null;
  }
}

// ! PAGINATION QUERIES

// ? Threat Briefings

export async function getPaginatedThreatBriefingPages(skip: number = 0, limit: number = 12, category?: string) {
  const query = `
    query GetPaginatedThreatBriefingPages($skip: Int!, $limit: Int!, $category: String) {
      threatBriefingPageCollection(
        skip: $skip, 
        limit: $limit, 
        order: date_DESC
        ${category ? ', where: { category: $category }' : ''}
      ) {
        total
        items {
          sys {
            id
            publishedAt
          }
          title
          date
          route
          slug
          category
          seoDescription
          coverImage {
            ${IMAGE_FIELDS}
          }
        }
      }
    }
  `;

  const variables = {
    skip,
    limit,
    ...(category && { category }),
  };

  try {
    const data = await fetchGraphQL(query, variables);
    return data.threatBriefingPageCollection;
  } catch (error) {
    console.error('Error fetching paginated threat briefing pages:', error);
    throw error;
  }
}

// ? Blogs

export async function getPaginatedBlogPages(skip: number = 0, limit: number = 12) {
  const query = `
    query GetPaginatedBlogPages($skip: Int!, $limit: Int!) {
      blogPageCollection(skip: $skip, limit: $limit, order: date_DESC) {
        total
        items {
          sys {
            id
          }
          title
          date
          route
          slug
          tags
          seoDescription
          coverImage {
            ${IMAGE_FIELDS}
          }
        }
      }
    }
  `;

  const variables = {
    skip,
    limit,
  };

  try {
    const data = await fetchGraphQL(query, variables);
    return data.blogPageCollection;
  } catch (error) {
    console.error('Error fetching paginated blog pages:', error);
    throw error;
  }
}

// ! NEWS AND PRESS QUERIES

export async function getNewsPressCards() {
  const query = `
    query GetNewsPressCards {
      listingTileCollection {
        items {
          ${NEWS_PRESS_CARDS_LISTING_FIELDS}
        }
      }
    }
  `;

  const response = await fetchGraphQL(query);
  return response.listingTileCollection.items || [];
}

export async function getNewsPressCardBySlug(slug: string) {
  const query = `
    query GetNewsPressCardBySlug($slug: String!) {
      listingTileCollection(where: { slug: $slug }, limit: 1) {
        items {
          ${NEWS_PRESS_CARDS_DETAIL_FIELDS}
        }
      }
    }
  `;

  try {
    const response = await fetchGraphQL(query, { slug });
    return response?.listingTileCollection?.items?.[0] || null;
  } catch (error) {
    console.error(`Error fetching news press card for slug ${slug}:`, error);
    return null;
  }
}

export async function getRelatedNewsPressCards(currentSlug: string, limit: number = 2) {
  const query = `
    query GetRelatedNewsPressCards($currentSlug: String!, $limit: Int!) {
      listingTileCollection(where: { slug_not: $currentSlug }, limit: $limit) {
        items {
          ${NEWS_PRESS_CARDS_LISTING_FIELDS}
        }
      }
    }
  `;

  const response = await fetchGraphQL(query, { currentSlug, limit });
  return response.listingTileCollection.items || [];
}

// ! ADDITIONAL SEARCH INDEX QUERIES

export async function getResourceLibrary(id: string) {
  const query = `
    query GetResourceLibrary($id: String!) {
      standardPage(id: $id) {
        sectionsCollection(limit: 10) {
          items {
            __typename
            ... on HeadingCardsComponent {
              cardsCollection {
                items {
                  ... on CtaCard {
                    title
                    description
                    ctaAction
                    route
                    slug
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  try {
    const data = await fetchGraphQL(query, { id }, false);
    return data?.standardPage.sectionsCollection.items || null;
  } catch (error) {
    console.error('Error fetching resource library:', error);
    throw error;
  }
}
