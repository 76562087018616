function SvgLinkIcon() {
    return (
      <div className="col-start-6 row-start-1 self-start justify-self-end rounded-md border-none bg-WHITE/20 p-10px text-WHITE hover:bg-opacity-60">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 12 12" fill="none">
          <path
            d="M1 11L10.9999 1M10.9999 1V10.6M10.9999 1H1.4"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }

  export default SvgLinkIcon;