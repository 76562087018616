/* eslint-disable simple-import-sort/imports */
import AboutDescriptionComponent from '@/app/components/ui/AboutDescriptionComponent';
import BlogListingComponent from '@/app/components/ui/Blog/BlogListingComponent';
import CardsComponent from '@/app/components/ui/Cards/CardsComponent';
import ComparisonComponent from '@/app/components/ui/ComparisonComponents/ComparisonComponent';
import FaqComponent from '@/app/components/ui/Accordion/FaqComponent';
import FeaturedImageComponent from '@/app/components/ui/FeaturedImageComponent';
import FeaturedTwoImageComponent from '@/app/components/ui/FeaturedTwoImageComponent';
import FormComponent from '@/app/components/ui/Forms/FormComponent';
import Heading from '@/app/components/ui/Heading/Heading';
import HeadingCardsComponent from '@/app/components/ui/Cards/HeadingCardsComponent';
import HeadingListingComponent from '@/app/components/ui/HeadingListingComponent';
import HeadingLogoComponent from '@/app/components/ui/HeadingLogoComponent';
import HeroComponentSingleColumn from '@/app/components/ui/Heros/HeroComponentSingleColumn';
import HeroComponentTwoColumn from '@/app/components/ui/Heros/HeroComponentTwoColumn';
import InvestorsComponent from '@/app/components/ui/InvestorsComponent';
import ListingComponent from '@/app/components/ui/ListingComponent/ListingComponent';
import ListingTextComponent from '@/app/components/ui/RichText/ListingTextComponent';
import LogoComponent from '@/app/components/ui/LogoComponent';
import MarqueeComponent from '@/app/components/ui/MarqueeComponent';
import NewsPressListingsComponent from '@/app/components/ui/NewsAndPress/NewsPressListingsComponent';
import OpenPositions from '@/app/components/ui/Careers/OpenPositions';
import ProductComponent from '@/app/components/ui/ProductCarousel/ProductComponent';
import ReviewAppraisalComponent from '@/app/components/ui/ReviewAppraisalComponent';
import RichTextComponent from '@/app/components/ui/RichText/RichTextComponent';
import StaffComponent from '@/app/components/ui/StaffComponent';
import StickyComponent from '@/app/components/ui/StickyComponent';
import ThreatListingComponent from '@/app/components/ui/ThreatBriefings/ThreatListingComponent';
import TwoColumnComponent from '@/app/components/ui/TwoColumnComponent';
import TwoColumnStickyComponent from '@/app/components/ui/TwoColumnStickyComponent';
import UseCasesCardsComponent from '@/app/components/ui/Cards/UseCasesCardsComponent';

const COMPONENT_MAP: { [key: string]: React.FC<any> } | any = {
  AboutDescriptionComponent,
  BlogListingComponent,
  CardsComponent,
  ComparisonComponent,
  FaqComponent,
  FeaturedImageComponent,
  FeaturedTwoImageComponent,
  FormComponent,
  Heading,
  HeadingCardsComponent,
  HeadingListingComponent,
  HeadingLogoComponent,
  HeroComponentSingleColumn,
  HeroComponentTwoColumn,
  InvestorsComponent,
  ListingComponent,
  ListingTextComponent,
  LogoComponent,
  MarqueeComponent,
  NewsPressListingsComponent,
  ProductComponent,
  ReviewAppraisalComponent,
  RichTextComponent,
  StaffComponent,
  StickyComponent,
  ThreatListingComponent,
  TwoColumnComponent,
  TwoColumnStickyComponent,
  UseCasesCardsComponent,
  OpenPositions,
};

export function componentResolver(typename: string | undefined) {
  if (!typename) {
    console.warn('Component resolver received undefined typename');
    return null;
  }
  const Component = COMPONENT_MAP[typename];

  if (!Component) {
    console.warn(`No component found for typename: ${typename}`);
    return () => (
      <div className="p-4 bg-SLATE_BLUE border border-red-500 text-white rounded-md my-5 first:mt-18 last:mb-18 max-w-[calc(100vw-50%)] mx-auto text-center space-y-2">
        <p className="font-Bold text-Body">Component Error</p>
        <p>Unknown component type: &quot;{typename}&quot;</p>
        <p>Please <a href="https://cyware.com/contact" className="text-blue-500 underline underline-offset-2">contact the development team</a> if you see this message.</p>
      </div>
    );
  }
  
  return Component;
}