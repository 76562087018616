import clsx from 'clsx';
import Image from 'next/image';
import { Link } from 'next-view-transitions';

import { getHref } from '@/app/lib/utils/getHref';

const backgroundColorClasses = {
  None: 'bg-transparent',
  Gray: 'bg-BLACK-100',
  'Slate Blue': 'bg-SLATE_BLUE',
  'Off Black': 'bg-OFF-BLACK',
} as const;

const accentColorClasses = {
  null: 'hover:border-WHITE/80 active:border-WHITE/80',
  None: 'border-0 hover:ring-1 hover:ring-WHITE/80 active:ring-WHITE/80',
  'Default (Light Gray)': 'hover:border-WHITE/80 active:border-WHITE/80',
  Green: 'hover:border-GREEN-500 active:border-GREEN-500 border-GREEN-500/60 border-GREEN-500/50',
  Blue: 'hover:border-BLUE-400 active:border-BLUE-400 border-BLUE-400/60 border-BLUE-400/50',
  Purple: 'hover:border-PURP-200 active:border-PURP-200 border-PURP-200/60 border-PURP-200/50',
} as const;

const accentColorTextClasses = {
  null: 'text-WHITE',
  'Default (Light Gray)': 'text-WHITE',
  Green: 'text-GREEN-500',
  Blue: 'text-BLUE-400',
  Purple: 'text-PURP-200',
} as const;

export default function CtaCardLarge({ card, className }: any) {
  const { label, title, description, backgroundColor, accentColor, ctaAction, route, slug, url, openTheLinkInANewTab, image } = card;
  const href: string = getHref(route, slug, url);

  return (
    <Link
      href={href}
      target={openTheLinkInANewTab ? '_blank' : '_self'}
      className={clsx(
        className,
        'grid min-h-[250px] grid-cols-6 gap-8 rounded-md border-[1.5px] border-WHITE/20 p-5 transition-colors duration-300 ease-linear hover:bg-transparent sm:p-10px',
        backgroundColorClasses[backgroundColor as keyof typeof backgroundColorClasses],
        accentColorClasses[accentColor as keyof typeof accentColorClasses],
      )}
    >
      <div className="col-span-5">
        {(label && !image) && (
          <p className={clsx('mb-2 text-Body font-bold', accentColorTextClasses[accentColor as keyof typeof accentColorTextClasses])}>
            {label}
          </p>
        )}
        {(title && !image) && <p className="text-H2-Mobile text-WHITE sm:text-Body-Large">{title}</p>}
        {image && <Image src={image.url} alt={image.description || 'Cta Card Large Image'} width={image.width} height={image.height} />}
      </div>
      {ctaAction === 'Download' ? <SvgDownloadIcon /> : <SvgLinkIcon />}
      {description && <p className="col-span-full max-w-[80ch] self-end text-Body text-WHITE/75">{description}</p>}
    </Link>
  );
}

function SvgLinkIcon() {
  return (
    <div className="col-start-6 row-start-1 self-start justify-self-end rounded-md border-none bg-WHITE/20 px-10px py-10px text-WHITE hover:bg-opacity-60">
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 12 12" fill="none">
        <path
          d="M1 11L10.9999 1M10.9999 1V10.6M10.9999 1H1.4"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

function SvgDownloadIcon() {
  return (
    <div className="col-start-6 row-start-1 self-start justify-self-end rounded-md border-none bg-WHITE/20 px-10px py-10px text-WHITE hover:bg-opacity-60">
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none">
        <path
          d="M1.66663 11H9.66663M5.66663 1V9M5.66663 9L9.16663 5.5M5.66663 9L2.16663 5.5"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
