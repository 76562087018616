import clsx from 'clsx';
import { DownloadIcon } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';

import type { TwoColumnComponent } from '@/app/types/contentful/types';

import ClientCTAButton from './Buttons/ClientCTAButton';

export default function HeroComponentTwoColumn({ content, locationData }: { content: TwoColumnComponent, locationData: string }) {
  const {
    twoColumnComponent: { itemLeft, featuredImage, ctaButton, showBackgroundColor, switchColumns, spacing },
  }: TwoColumnComponent = content;

  const ImageComponent = (
    <Image
      src={featuredImage?.url}
      alt={featuredImage?.description || featuredImage?.title}
      width={featuredImage?.width}
      height={featuredImage?.height}
      sizes="(max-width: 768px) 100vw, 768px"
      draggable={false}
      className={clsx(
        'h-full w-full object-cover xl:object-contain md:mt-5',
        switchColumns
          ? 'col-span-6 row-start-1 md:col-span-full md:row-start-2'
          : 'col-start-7 col-end-13 row-start-1 md:col-span-full md:row-start-2',
      )}
    />
  );

  const DownloadIconWrapper = () => (
    <div className="absolute top-4 right-4 bg-white/90 p-2 rounded-full shadow-lg cursor-pointer group lg:top-10 lg:right-2 group-hover:bg-GREEN-500 transition-colors duration-300">
      <div className="relative">
        <DownloadIcon className="w-6 h-6 text-SLATE_BLUE md:w-4 md:h-4" />
        <span className="absolute -top-10 left-1/2 -translate-x-1/2 bg-black text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap md:whitespace-normal">
          Click to view full size or download
        </span>
      </div>
    </div>
  );

  return (
    <section
      className={clsx('h-full text-WHITE', showBackgroundColor ? 'bg-SLATE_BLUE' : 'bg-none', {
        'py-40 sm:py-20': spacing === 'Large',
        'py-20 sm:py-15': spacing === 'Medium',
        'py-10': spacing === 'Small',
        'py-5': spacing === 'Default',
        'py-0': spacing === 'None',
      })}
    >
      <div className="container grid w-full grid-cols-12 px-15 py-15 lg:px-5 lg:py-10 gap-x-12 sm:gap-x-0">
        <article
          className={clsx(
            'flex flex-col items-start justify-center gap-y-2',
            switchColumns
              ? 'col-start-8 col-end-13 row-start-1 md:col-span-full md:justify-self-center'
              : 'col-span-6 row-start-1 md:col-span-full',
            {
              'pb-30': itemLeft.spacing === 'Large',
              'pb-20': itemLeft.spacing === 'Medium',
              'pb-10': itemLeft.spacing === 'Small',
              'pb-5': itemLeft.spacing === 'Default',
              'pb-0 md:pb-10 sm:pb-0': itemLeft.spacing === 'None',
            },
          )}
        >
          <h1 className="text-[1rem] font-semibold text-GREEN-500">{itemLeft.label}</h1>

          <p
            className={clsx(
              'w-full max-w-[15ch] text-pretty font-bold md-lg:text-H3 md:max-w-full md:text-H1-Mobile sm:text-H3',
              {
                'text-Display': itemLeft.titleFontSize === 'Display',
                'text-H1': itemLeft.titleFontSize === 'H1',
                'text-H2': itemLeft.titleFontSize === 'H2',
                'text-H3': itemLeft.titleFontSize === 'H3',
              },
            )}
          >
            {itemLeft.title}
          </p>

          <p className="mt-2 w-full max-w-[50ch]  whitespace-pre-wrap text-Body-Large md-lg:text-Body sm:mt-2 sm:text-Body">
            {itemLeft.description}
          </p>
          {ctaButton && !itemLeft.ctaButton && (
            <ClientCTAButton buttonData={ctaButton} className="mt-6 inline-block text-xs font-semibold sm:mt-6" />
          )}

          {!ctaButton && itemLeft.ctaButton && (
            <ClientCTAButton buttonData={itemLeft.ctaButton} className="mt-6 inline-block text-xs font-semibold sm:mt-6" />
          )}
        </article>

        {featuredImage?.url && (
          locationData === 'rsa-2025-happy-hour' ? (
            <div className={clsx(
              'relative',
              switchColumns
                ? 'col-span-6 row-start-1 md:col-span-full md:row-start-2'
                : 'col-start-7 col-end-13 row-start-1 md:col-span-full md:row-start-2',
            )}>
              <Link href={featuredImage.url} target="_blank" rel="noopener noreferrer" className='group'>
                {ImageComponent}
                <DownloadIconWrapper />
              </Link>
            </div>
          ) : (
            ImageComponent
          )
        )}
      </div>
    </section>
  );
}

// ! TODO: REVERT TO THIS WHEN HAPPY HOUR IS DONE

// import clsx from 'clsx';
// import Image from 'next/image';

// import type { TwoColumnComponent } from '@/app/types/contentful/types';

// import ClientCTAButton from './Buttons/ClientCTAButton';

// export default function HeroComponentTwoColumn({ content }: { content: TwoColumnComponent }) {
//   const {
//     twoColumnComponent: { itemLeft, featuredImage, ctaButton, showBackgroundColor, switchColumns, spacing },
//   }: TwoColumnComponent = content;

//   return (
//     <section
//       className={clsx('h-full text-WHITE', showBackgroundColor ? 'bg-SLATE_BLUE' : 'bg-none', {
//         'py-40 sm:py-20': spacing === 'Large',
//         'py-20 sm:py-15': spacing === 'Medium',
//         'py-10': spacing === 'Small',
//         'py-5': spacing === 'Default',
//         'py-0': spacing === 'None',
//       })}
//     >
//       <div className="container grid w-full grid-cols-12 px-15 py-15 lg:px-5 lg:py-10 gap-x-12 sm:gap-x-0">
//         <article
//           className={clsx(
//             'flex flex-col items-start justify-center gap-y-2',
//             switchColumns
//               ? 'col-start-8 col-end-13 row-start-1 md:col-span-full md:justify-self-center'
//               : 'col-span-6 row-start-1 md:col-span-full',
//             {
//               'pb-30': itemLeft.spacing === 'Large',
//               'pb-20': itemLeft.spacing === 'Medium',
//               'pb-10': itemLeft.spacing === 'Small',
//               'pb-5': itemLeft.spacing === 'Default',
//               'pb-0 md:pb-10 sm:pb-0': itemLeft.spacing === 'None',
//             },
//           )}
//         >
//           <h1 className="text-[1rem] font-semibold text-GREEN-500">{itemLeft.label}</h1>

//           <p
//             className={clsx(
//               'w-full max-w-[15ch] text-pretty font-bold md-lg:text-H3 md:max-w-full md:text-H1-Mobile sm:text-H3',
//               {
//                 'text-Display': itemLeft.titleFontSize === 'Display',
//                 'text-H1': itemLeft.titleFontSize === 'H1',
//                 'text-H2': itemLeft.titleFontSize === 'H2',
//                 'text-H3': itemLeft.titleFontSize === 'H3',
//               },
//             )}
//           >
//             {itemLeft.title}
//           </p>

//           <p className="mt-2 w-full max-w-[50ch]  whitespace-pre-wrap text-Body-Large md-lg:text-Body sm:mt-2 sm:text-Body">
//             {itemLeft.description}
//           </p>
//           {ctaButton && !itemLeft.ctaButton && (
//             <ClientCTAButton buttonData={ctaButton} className="mt-6 inline-block text-xs font-semibold sm:mt-6" />
//           )}

//           {!ctaButton && itemLeft.ctaButton && (
//             <ClientCTAButton buttonData={itemLeft.ctaButton} className="mt-6 inline-block text-xs font-semibold sm:mt-6" />
//           )}
//         </article>

//         {featuredImage?.url && (
//           <Image
//             src={featuredImage?.url}
//             alt={featuredImage?.description || featuredImage?.title}
//             width={featuredImage?.width}
//             height={featuredImage?.height}
//             sizes="(max-width: 768px) 100vw, 768px"
//             draggable={false}
//             className={clsx(
//               'h-full w-full object-cover xl:object-contain md:mt-5',
//               switchColumns
//                 ? 'col-span-6 row-start-1 md:col-span-full md:row-start-2'
//                 : 'col-start-7 col-end-13 row-start-1 md:col-span-full md:row-start-2',
//             )}
//           />
//         )}
//       </div>
//     </section>
//   );
// }
