import { Suspense } from 'react';

import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';
import type { ContentfulImage } from '@/app/types/contentful/types';

import { SvgLinesComponent } from '../SvgLinesComponent';
import ClientListingComponent from './ClientListingComponent';

interface ListingComponentProps {
  content: {
    listingComponent: {
      cardsCollection: {
        items: ListingCardProps[];
      };
      numberOfCards: number;
      showGridLines: boolean;
      backgroundType: string;
      spacingTop: number;
      spacingBottom: number;
    };
  };
}

export interface ListingCardProps {
  _id: string;
  title: string;
  date: string;
  url: string;
  featuredImage: ContentfulImage;
}

const backgroundTypeClasses: { [key: string]: string | string[] | null } = {
  None: 'bg-BLACK',
  'Slate Blue': 'bg-SLATE_BLUE',
  'Off Black': 'bg-OFF-BLACK',
  'Purple Gradient Top': 'bg-gradient-top-purple bg-top',
  'Blue Gradient Top': 'bg-gradient-top-blue bg-top',
  'Purple Gradient Center': 'bg-gradient-center-purple bg-center',
  'Blue Gradient Center': 'bg-gradient-center-blue bg-center',
  'Purple Gradient Bottom': 'bg-gradient-bottom-purple bg-bottom',
  'Blue Gradient Bottom': 'bg-gradient-bottom-blue bg-bottom',
  'Purple Gradient Top/Bottom': ['bg-gradient-top-purple bg-top', 'bg-gradient-bottom-purple bg-bottom'],
  'Blue Gradient Top/Bottom': ['bg-gradient-top-blue bg-top', 'bg-gradient-bottom-blue bg-bottom'],
} as const;

export default function ListingComponent({ content }: ListingComponentProps) {
  const {
    listingComponent: { cardsCollection, numberOfCards, backgroundType, showGridLines, spacingTop, spacingBottom },
  } = content;

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  const renderBackground = () => {
    const gradientClass = backgroundTypeClasses[backgroundType];

    if (Array.isArray(gradientClass)) {
      return (
        <>
          <div className={`pointer-events-none absolute -z-10 h-full w-screen bg-cover bg-no-repeat ${gradientClass[0]}`} />
          <div className={`pointer-events-none absolute -z-10 h-full w-screen bg-cover bg-no-repeat ${gradientClass[1]}`} />
        </>
      );
    }

    return gradientClass ? (
      <div className={`pointer-events-none absolute left-0 top-0 -z-20 h-full w-screen bg-cover bg-no-repeat ${gradientClass}`} />
    ) : null;
  };

  return (
    <section className={`relative ${paddingTop} ${paddingBottom} scroll-mt-60`}>
      {showGridLines && (
        <div className="absolute left-0 top-0 -z-10 h-full w-full text-WHITE">
          <SvgLinesComponent />
        </div>
      )}
      {renderBackground()}

      <Suspense fallback={<div>Loading...</div>}>
        <ClientListingComponent
          cardsCollection={cardsCollection}
          numberOfCards={numberOfCards}
        />
      </Suspense>
    </section>
  );
}