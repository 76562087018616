import Image from 'next/image';
import { Link } from 'next-view-transitions';
import React from 'react';

import { SvgCollaborate, SvgIntelExchange, SvgOrchestrate, SvgRespond } from '@/app/components/ui/SVG Components/SvgProducts';
import { ContenfulProductComponent } from '@/app/types/contentful/types';

import CarouselDesktop from './CarouselDesktop';
import CarouselMobile from './CarouselMobile';

export default function ProductComponent({ content }: { content: ContenfulProductComponent }) {
  const {
    productComponent: { title, carouselTitle, carouselDescription, carouselItemsCollection, productImageDesktop, useImage },
  }: ContenfulProductComponent = content;

  return (
    <section
      className="container relative mx-auto mt-25 grid grid-cols-12 gap-y-10 md:mt-20 md:gap-y-5 sm:mt-15"
      aria-labelledby="product-section-title"
    >
      {/* Product Header */}
      <ProductHeader title={title} useImage={useImage} productImageDesktop={productImageDesktop} />

      {/* Product Item */}
      <ProductItem />

      {/* Carousel Desktop */}
      {carouselItemsCollection && (
        <CarouselDesktop
          carouselTitle={carouselTitle}
          carouselDescription={carouselDescription}
          carouselItemsCollection={carouselItemsCollection}
        />
      )}

      {/* Carousel Mobile */}
      {carouselItemsCollection && (
        <CarouselMobile
          carouselTitle={carouselTitle}
          carouselDescription={carouselDescription}
          carouselItemsCollection={carouselItemsCollection}
        />
      )}
    </section>
  );
}

function ProductHeader({ title, useImage, productImageDesktop }: { title: string; useImage: boolean; productImageDesktop: any }) {
  return (
    <article className="col-span-full flex flex-col items-center justify-center gap-y-10">
      {title && (
        <h1
          id="product-section-title"
          className="col-span-full max-w-[25ch] text-center text-H1 font-semibold tracking-normal text-WHITE sm:text-center sm:text-H1-Mobile"
        >
          {title}
        </h1>
      )}

      {useImage && productImageDesktop && (
        <Image
          src={productImageDesktop.url}
          alt={productImageDesktop.description || productImageDesktop.title}
          width={productImageDesktop.width}
          height={productImageDesktop.height}
          sizes="(max-width: 768px) 100vw, 768px"
          draggable="false"
          className="h-full w-full max-w-[1240px] object-contain"
        />
      )}
    </article>
  );
}

export function ProductItem() {
  return (
    <article className="col-span-full mb-10 w-full p-8">
      <section className="w-full space-y-18 md:space-y-16" aria-label="Product cards">
        {/* Cards container */}
        <article
          className="grid auto-rows-[minmax(200px,1fr)] grid-cols-3 gap-6 md:grid-cols-1"
          role="list"
          aria-label="Core product offerings"
        >
          {/* Intel Exchange Card */}
          <Link
            href="/products/intel-exchange"
            className="group relative rounded-lg border-[3px] border-gray-600 bg-SLATE_BLUE transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 focus:ring-offset-SLATE_BLUE min-md:hover:bg-SLATE-BLUE-950 min-md:hover:shadow-[0_0_10px_rgba(0,0,0,0.5)] min-md:hover:shadow-blue-400 min-md:hover:ring-2 min-md:hover:ring-blue-400"
            style={{
              alignContent: 'center',
            }}
            title="Click to learn more about Intel Exchange"
            aria-labelledby="intel-exchange-title"
            aria-describedby="intel-exchange-desc"
            role="listitem"
          >
            <div className="space-y-4 p-6 md:flex md:flex-col md:items-center md:justify-center">
              <SvgIntelExchange className="h-12 w-12 text-blue-400" aria-hidden="true" />
              <h2 id="intel-exchange-title" className="text-H3 font-bold text-white">
                Intel Exchange
              </h2>
              <p id="intel-exchange-desc" className="text-Body text-gray-400 md:text-center">
                Automated IT Operations
              </p>
            </div>
            {/* Connector line - decorative element */}
            <div
              className="absolute -bottom-20 left-1/2 -z-10 h-20 w-[2px] transform bg-gray-600 transition-colors duration-300 md:bottom-0 md:h-10 md:translate-y-full md:border-l-2 md:border-gray-600 md:bg-transparent min-md:group-hover:bg-blue-300"
              aria-hidden="true"
            />
          </Link>

          {/* Collaborate Card */}
          <Link
            href="/products/collaborate"
            className="group relative rounded-lg border-[3px] border-gray-600 bg-SLATE_BLUE transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 focus:ring-offset-SLATE_BLUE min-md:hover:bg-SLATE-BLUE-950 min-md:hover:shadow-[0_0_10px_rgba(0,0,0,0.5)] min-md:hover:shadow-green-400 min-md:hover:ring-2 min-md:hover:ring-green-400"
            style={{
              alignContent: 'center',
            }}
            title="Click to learn more about Collaborate"
            aria-labelledby="collaborate-title"
            aria-describedby="collaborate-desc"
            role="listitem"
          >
            <div className="space-y-4 p-6 md:flex md:flex-col md:items-center md:justify-center">
              <SvgCollaborate className="h-12 w-12 text-green-400" aria-hidden="true" />
              <h2 id="collaborate-title" className="text-H3 font-bold text-white">
                Collaborate
              </h2>
              <p id="collaborate-desc" className="text-Body text-gray-400 md:text-center">
                Advisory Sharing & Human Collaboration
              </p>
            </div>
            {/* Connector line - decorative element */}
            <div
              className="absolute -bottom-20 left-1/2 -z-10 h-20 w-[2px] transform bg-gray-600 transition-colors duration-300 md:bottom-0 md:h-10 md:translate-y-full md:border-l-2 md:border-gray-600 md:bg-transparent min-md:group-hover:bg-green-300"
              aria-hidden="true"
            />
          </Link>

          {/* Respond Card */}
          <Link
            href="/products/respond"
            className="group relative rounded-lg border-[3px] border-gray-600 bg-SLATE_BLUE transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-offset-2 focus:ring-offset-SLATE_BLUE min-md:hover:bg-SLATE-BLUE-950 min-md:hover:shadow-[0_0_10px_rgba(0,0,0,0.5)] min-md:hover:shadow-purple-400 min-md:hover:ring-2 min-md:hover:ring-purple-400"
            style={{
              alignContent: 'center',
            }}
            title="Click to learn more about Respond"
            aria-labelledby="respond-title"
            aria-describedby="respond-desc"
            role="listitem"
          >
            <div className="space-y-4 p-6 md:flex md:flex-col md:items-center md:justify-center">
              <SvgRespond className="h-12 w-12 text-purple-400" aria-hidden="true" />
              <h2 id="respond-title" className="text-H3 font-bold text-white">
                Respond
              </h2>
              <p id="respond-desc" className="text-Body text-gray-400 md:text-center">
                Case Management & IR
              </p>
            </div>
            {/* Connector line - decorative element */}
            <div
              className="absolute -bottom-20 left-1/2 -z-10 h-20 w-[2px] transform bg-gray-600 transition-colors duration-300 md:bottom-0 md:translate-y-full md:border-l-2 md:border-gray-600 md:bg-transparent min-md:group-hover:bg-purple-300"
              aria-hidden="true"
            />
          </Link>
        </article>

        {/* Bottom card */}
        <article
          className="h-[200px] w-full rounded-lg border-[3px] border-gray-600 bg-SLATE_BLUE transition-colors duration-300 min-md:hover:bg-SLATE-BLUE-950 min-md:hover:shadow-[0_0_20px_rgba(0,0,0,0.5)] min-md:hover:shadow-blue-600 min-md:hover:ring-2 min-md:hover:ring-blue-600"
          role="listitem"
          aria-label="Orchestrate product card"
        >
          <Link
            href="/products/orchestrate"
            className="group flex h-full w-full flex-col items-center justify-between rounded-lg p-6 text-center focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-SLATE_BLUE"
            title="Click to learn more about Orchestrate"
            aria-labelledby="orchestrate-title"
            aria-describedby="orchestrate-desc"
          >
            <div className="mb-4">
              <SvgOrchestrate className="h-12 w-12 text-blue-400" aria-hidden="true" />
            </div>
            <div>
              <h2 id="orchestrate-title" className="mb-2 text-H3 font-bold text-white">
                Orchestrate
              </h2>
              <p id="orchestrate-desc" className="text-Body text-gray-400 md:text-center">
                Vendor-Neutral, Low-Code Orchestration & Automation
              </p>
            </div>
          </Link>
        </article>
      </section>
    </article>
  );
}
