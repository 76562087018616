import clsx from 'clsx';

const backgroundTypeClasses: { [key: string]: string | string[] | null } = {
  None: 'bg-BLACK',
  'Slate Blue': 'bg-SLATE_BLUE',
  'Off Black': 'bg-OFF-BLACK',
  'Purple Gradient Top': 'bg-gradient-top-purple bg-top',
  'Blue Gradient Top': 'bg-gradient-top-blue bg-top',
  'Purple Gradient Center': 'bg-gradient-center-purple bg-center',
  'Blue Gradient Center': 'bg-gradient-center-blue bg-center',
  'Purple Gradient Bottom': 'bg-gradient-bottom-purple bg-bottom',
  'Blue Gradient Bottom': 'bg-gradient-bottom-blue bg-bottom',
  'Purple Gradient Top/Bottom': ['bg-gradient-top-purple bg-top', 'bg-gradient-bottom-purple bg-bottom'],
  'Blue Gradient Top/Bottom': ['bg-gradient-top-blue bg-top', 'bg-gradient-bottom-blue bg-bottom'],
} as const;

const renderBackground = (backgroundType: string) => {
  const gradientClass = backgroundTypeClasses[backgroundType];

  if (Array.isArray(gradientClass)) {
    return (
      <>
        <div className={clsx('pointer-events-none absolute -z-10 h-full w-screen bg-cover bg-no-repeat', gradientClass[0])} />
        <div className={clsx('pointer-events-none absolute -z-10 h-full w-screen bg-cover bg-no-repeat', gradientClass[1])} />
      </>
    );
  }

  return gradientClass ? (
    <div className={clsx('pointer-events-none absolute left-0 top-0 -z-20 h-full w-screen bg-cover bg-no-repeat', gradientClass)} />
  ) : null;
};

export default renderBackground;
