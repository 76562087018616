import clsx from 'clsx';

import { ContenfulTextDescriptionCardSmall } from '@/app/types/contentful/types';
const backgroundColorClasses = {
  None: 'bg-transparent',
  'Slate Blue': 'bg-SLATE_BLUE/75',
  'Purple': 'bg-PURP/15',
  'Blue': 'bg-BLUE/10',
  'Off Black': 'bg-OFF-BLACK/60',
} as const;

const borderColorClasses = {
  "Default (Light Gray)": 'border-2 border-WHITE/50',
  Green: 'border border-GREEN-500',
  Purple: 'border border-PURP',
  Blue: 'border border-BLUE-400',
} as const;

export default function TextDescriptionCardSmall({ card, className }: { card: ContenfulTextDescriptionCardSmall; className: string }) {
  const { title, description, underlineTitle, boldDescription, addBackground, borderColor, backgroundColor } = card;

  return (
    <div
      className={clsx(className, 'flex min-h-[200px] w-full flex-col gap-6 whitespace-pre-wrap rounded-lg text-WHITE/90 p-5', {
        'bg-SLATE_BLUE': addBackground,
      },
      borderColorClasses[borderColor as keyof typeof borderColorClasses],
      backgroundColorClasses[backgroundColor as keyof typeof backgroundColorClasses],
    )}
    >
      <h2
        className={clsx('text-[1rem] font-bold text-balance', {
          'underline': underlineTitle,
        })}
      >
        {title}
      </h2>
      <p className={`text-balance text-Body ${boldDescription ? 'font-semibold' : ''}`}>{description}</p>
    </div>
  );
}
