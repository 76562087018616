import clsx from 'clsx';

import { ContenfulTextDescriptionCardLarge } from '@/app/types/contentful/types';

const backgroundColorClasses = {
  None: 'bg-transparent',
  'Slate Blue': 'bg-SLATE_BLUE/75',
  'Purple': 'bg-PURP/15',
  'Blue': 'bg-BLUE/10',
  'Off Black': 'bg-OFF-BLACK/60',
} as const;

const borderColorClasses = {
  "Default (Light Gray)": 'border-2 border-WHITE/50',
  Green: 'border border-GREEN-500',
  Purple: 'border border-PURP',
  Blue: 'border border-BLUE-400',
} as const;

export default function TextDescriptionCardLarge({ card, className }: { card: ContenfulTextDescriptionCardLarge; className: string }) {
  const { title, description, underlineTitle, boldDescription, addBackground, backgroundColor, borderColor } = card;

  return (
    <div
      className={clsx(
        className,
        'flex w-full flex-col gap-8 whitespace-pre-wrap rounded-lg p-5 text-WHITE/90',
        {
          'bg-SLATE_BLUE p-5': addBackground,
        },
        borderColorClasses[borderColor as keyof typeof borderColorClasses],
        backgroundColorClasses[backgroundColor as keyof typeof backgroundColorClasses],
      )}
    >
      {title && (
        <h2
          className={clsx('text-Body-Large font-semibold', {
            underline: underlineTitle,
          })}
        >
          {title}
        </h2>
      )}
      <p className={`max-w-[70ch] text-balance text-Body ${boldDescription ? 'font-semibold' : ''}`}>{description}</p>
    </div>
  );
}
