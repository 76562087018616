import clsx from 'clsx';

import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';
import type { ContentfulStickyComponentCard, TwoColumnStickyComponent } from '@/app/types/contentful/types';

import ClientCTAButton from './Buttons/ClientCTAButton';
import LinkButton from './Buttons/LinkButton';

export default function TwoColumnStickyComponent({ content }: { content: TwoColumnStickyComponent }) {
  const {
    twoColumnStickyComponent: { spacingTop, spacingBottom },
  }: TwoColumnStickyComponent = content;

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  return (
    <section className={`container grid h-full w-full grid-cols-12 gap-y-20 text-WHITE md-lg:gap-x-5 ${paddingTop} ${paddingBottom}`}>
      <StickyComponentDesktop content={content} />
      <StickyComponentMobile content={content} />
    </section>
  );
}

function StickyComponentDesktop({ content }: { content: TwoColumnStickyComponent }) {
  const {
    twoColumnStickyComponent: { label, title, description, stickyCardsCollection, ctaButton },
  } = content;
  return (
    <>
      <article className="col-span-4 h-full w-full md-lg:col-span-5 md:hidden">
        <div className="sticky top-32 ml-9 h-auto w-full whitespace-pre-wrap text-balance object-contain">
          {label && <h1 className="text-[1rem] font-semibold text-GREEN-500">{label}</h1>}
          <h2 className="mt-2 text-H2 leading-[110%] md-lg:text-H2-Mobile">{title}</h2>
          <p className="mt-4 max-w-[50ch] text-balance text-Body">{description}</p>
          {ctaButton && <ClientCTAButton buttonData={ctaButton} className="mt-8 inline-block text-xs font-semibold sm:mt-6" />}
        </div>
      </article>

      <article className="col-start-7 col-end-11 flex flex-col items-baseline gap-y-[200px] md-lg:col-end-13 md-lg:gap-y-[150px] md:hidden">
        {stickyCardsCollection?.items.map((card: ContentfulStickyComponentCard, index: number) => {
          const { _id, title, description, borderColor } = card;
          return (
            <div
              key={_id + index}
              className={clsx('grid gap-y-10 border-l-2 px-10 py-5', {
                'border-GREEN-500': borderColor === 'Green',
                'border-PURP': borderColor === 'Purple',
                'border-BLUE': borderColor === 'Blue',
                'border-BLUE-400': borderColor === 'Light Blue',
              })}
            >
              <h1 className="col-span-full row-start-1 text-H1-Mobile md-lg:text-H3">{title}</h1>
              <p className="col-span-full row-start-2 text-Body-Large md-lg:text-Body">{description}</p>
            </div>
          );
        })}
      </article>
    </>
  );
}

function StickyComponentMobile({ content }: { content: TwoColumnStickyComponent }) {
  const {
    twoColumnStickyComponent: { label, title, description, stickyCardsCollection, ctaButton },
  } = content;
  return (
    <>
      <article className="col-start-1 col-end-10 row-start-1 mb-5 hidden h-full w-full whitespace-pre-wrap md:block sm:col-span-full">
        {label && <h1 className="text-Pill text-GREEN-500">{label}</h1>}
        <h2 className="mt-4 text-H3 leading-[100%]">{title}</h2>
        <p className="mt-8 max-w-[50ch] text-pretty text-Body">{description}</p>
        {ctaButton && (
          <LinkButton
            hrefData={ctaButton}
            label={ctaButton.label}
            buttonType={ctaButton.buttonType}
            className="mt-8 inline-block text-xs font-semibold sm:mt-6"
          />
        )}
      </article>

      <article className="col-span-full row-start-2 hidden flex-col items-baseline gap-y-15 md:flex">
        {stickyCardsCollection?.items.map((card: ContentfulStickyComponentCard, index: number) => {
          const { _id, title, description, borderColor } = card;
          return (
            <div
              className={clsx('grid gap-y-5 border-l-2 p-5', {
                'border-GREEN-500': borderColor === 'Green',
                'border-PURP': borderColor === 'Purple',
                'border-BLUE': borderColor === 'Blue',
                'border-BLUE-400': borderColor === 'Light Blue',
              })}
              key={_id + index}
            >
              <h1 className="col-span-full row-start-1 text-H3">{title}</h1>
              <p className="col-span-full row-start-2 text-Body">{description}</p>
            </div>
          );
        })}
      </article>
    </>
  );
}
