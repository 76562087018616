import clsx from 'clsx';
import Image from 'next/image';
import { Link } from 'next-view-transitions';

import { getHref } from '@/app/lib/utils/getHref';
import { ContenfulTile } from '@/app/types/contentful/types';

export default function Tile({ card, className }: { card: ContenfulTile; className: string }) {
  const { label, title, description, icon, showIcon, makeTitleLarge, borderColor, route, slug } = card;
  const href: string = getHref(route, slug, null);

  const TileContent = () => (
    <div
      className={clsx(
        `${className} flex w-full flex-col justify-between gap-10 whitespace-pre-wrap rounded-lg border bg-OFF-BLACK p-5 text-WHITE sm:min-h-[200px] min-h-[50px] sm:mt-0 transition-all duration-300`,
        {
          'border-WHITE/20': borderColor === 'Default (Light Gray)' || borderColor === null,
          'border-GREEN-500': borderColor === 'Green',
          'border-PURP': borderColor === 'Purple',
          'border-BLUE-400': borderColor === 'Blue',
        },
        {
          'cursor-pointer': route && slug,
        },
        {
          'hover:border-WHITE/40 hover:bg-WHITE/5': (slug && route && borderColor === 'Default (Light Gray)') || borderColor === null,
          'hover:border-GREEN-500 hover:bg-GREEN-500/5': slug && route && borderColor === 'Green',
          'hover:border-PURP hover:bg-PURP/5': slug && route && borderColor === 'Purple',
          'hover:border-BLUE-400 hover:bg-BLUE-400/5': slug && route && borderColor === 'Blue',
        }
      )}
    >
      {showIcon ? (
        <>
          <div className="flex flex-col items-start gap-y-0.5">
            {label && (
              <h1
                className={clsx('mb-1 text-Body md:text-xs sm:text-xxs', {
                  'text-WHITE': borderColor === 'Default (Light Gray)' || borderColor === null,
                  'text-GREEN-500': borderColor === 'Green',
                  'text-PURP': borderColor === 'Purple',
                  'text-BLUE-400': borderColor === 'Blue',
                })}
              >
                {label}
              </h1>
            )}
            {icon.url && (
              <span className="flex items-center">
                <Image
                  src={icon?.url}
                  alt={icon?.description || icon?.title}
                  width={icon?.width}
                  height={icon?.height}
                  sizes="(max-width: 768px) 100vw, 768px"
                  draggable={false}
                  className={clsx('mr-2 w-7 object-contain', makeTitleLarge && 'mr-5 w-auto')}
                />
                <h2
                  className={clsx({
                    'max-w-[20ch] text-H3 leading-none': makeTitleLarge,
                    'max-w-[30ch] text-H4': !makeTitleLarge,
                  })}
                >
                  {title}
                </h2>
              </span>
            )}
          </div>
          {
            description && (
              <p className="text-pretty text-Body-Large-Medium text-WHITE/60">{description}</p>
            )
          }
        </>
      ) : (
        <>
          <div className="flex flex-col gap-y-0.5">
            {label && (
              <h1
                className={clsx('text-Body md:text-xs sm:text-xxs', {
                  'text-WHITE': borderColor === 'Default (Light Gray)' || borderColor === null,
                  'text-GREEN-500': borderColor === 'Green',
                  'text-PURP': borderColor === 'Purple',
                  'text-BLUE-400': borderColor === 'Blue',
                })}
              >
                {label}
              </h1>
            )}
            <h2
              className={clsx({
                'max-w-[22ch] text-H3 leading-none': makeTitleLarge,
                'max-w-[30ch] text-H4': !makeTitleLarge,
              })}
            >
              {title}
            </h2>
          </div>
          {
            description && (
              <p className="text-pretty text-Body text-WHITE/60">{description}</p>
            )
          }
        </>
      )}
    </div>
  );

  return route && slug ? (
    <Link href={href} className="contents">
      <TileContent />
    </Link>
  ) : (
    <TileContent />
  );
}
