import Image from 'next/image';

import ClientCTAButton from '@/app/components/ui/Buttons/ClientCTAButton';
import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';

export default function SingleColumnSectionsComponent({ content }: any) {
  const {
    stickyComponent: {
      title,
      subtitle,
      twoColumnItemCollection,
      spacingTop,
      spacingBottom,
    },
  } = content;

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-32';

  return (
    <section className={`relative bg-SLATE_BLUE ${paddingTop} ${paddingBottom}`}>
      <div className="overflow-hidden px-5 md:px-2">
        <article className="flex flex-col items-center py-25 text-WHITE md:mb-[20px]">
          <h1 className="max-w-[20ch] text-center text-H1 font-bold md:text-H1-Mobile">{title}</h1>
          <p className="mt-4 max-w-[40ch] text-center text-Body font-light sm:text-Body">{subtitle}</p>
        </article>

        <div className="grid grid-cols-12 gap-y-10 md:gap-y-5">
          {twoColumnItemCollection.items.map((item: any, index: any) => {
            return (
              <div
                key={index}
                className={`col-span-12 grid grid-cols-12 gap-y-8 ${index % 3 === 0 ? 'border-DEEPPURPLE-500 bg-[#4c0b5d]/20' : index % 3 === 1 ? 'border-BLUE bg-[#0d195a]/20' : 'border-GREEN-500 bg-[#012c21]/20'} rounded-md border-2 pb-10 pt-20 md-xl:pt-5`}
              >
                <div className="col-span-12 flex flex-col items-center justify-center text-WHITE md:items-start md:p-4">
                  <h1 className="mb-4 max-w-[900px] text-center text-H2 font-bold lg:text-H1 md:max-w-[90%] md:text-left sm:text-H1-Mobile">
                    {item.title}
                  </h1>
                  <p className="max-w-[900px] text-center text-Body-Large md:max-w-[90%] md:text-left">{item.description}</p>

                  {item.ctaButton && <ClientCTAButton buttonData={item.ctaButton} className="my-8" />}
                </div>
                <div className="col-span-12">
                  <ItemComponent item={item} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

const ItemComponent = ({ item }: any) => {
  const { featuredImage } = item;
  return (
    <div className="mx-auto w-[70%] object-contain md:w-[80%]">
      <Image
        alt={featuredImage?.description || featuredImage?.title}
        draggable={false}
        src={featuredImage?.url}
        width={featuredImage?.width}
        height={featuredImage?.height}
        title={item.title || item.description || ''}
        className="h-full w-full object-contain"
      />
    </div>
  );
};

// function TwoColumnComponentMobile({ content }: any) {
//   const { title, subtitle, twoColumnItemCollection } = content;

//   return (
//     <section className="relative mx-auto hidden bg-WHITE md:block">
//       <div className="container">
//         <article className="mb-[150px] md:mb-[70px] flex flex-col items-center pt-75px">
//           <h1 className="text-center text-balance text-H1-Mobile font-bold">{title}</h1>
//           <p className="text-center text-balance text-Body font-light mt-2">{subtitle}</p>
//         </article>

//         {twoColumnItemCollection.items.map((item: any, index: any) => {
//           return (
//             <div className={clsx('mb-40 flex flex-col', { 'pb-20': index === twoColumnItemCollection.items.length - 1 })} key={index}>
//               <h1 className="mb-4 max-w-[550px] text-H1-Mobile font-bold">{item.title}</h1>
//               <p className="text-Body-mobile max-w-[580px] text-balance">{item.description}</p>
//               {item.ctaButton && (
//                 <LinkButton
//                   hrefData={item.ctaButton}
//                   label={item.ctaButton.label}
//                   buttonType={item.ctaButton.buttonType}
//                   className="mt-8 inline-block self-start font-semibold"
//                 />
//               )}
//               <div className="mt-20 w-full">
//                 <ItemComponent item={item} />
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </section>
//   );
// }
