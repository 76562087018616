// ! CYWARE GENERIC QUERIES START

export const IMAGE_FIELDS = `
    __typename
    title
    description
    width
    height
    url
`;

export const CTA_BUTTON_FIELDS = `
    label
    route
    slug
    url
    buttonType
    animated
`;

export const FORM_BUTTON_FIELDS = `
  label
  buttonType
  formType {
    sys {
      id
    }
    formType
  }
`;

export const BUTTON_FIELDS = `
  __typename
  ... on ButtonComponent {
    ${CTA_BUTTON_FIELDS}
  }
  ... on FormButton {
    ${FORM_BUTTON_FIELDS}
  }
`;

export const LINK_FIELDS = `
  ... on Link {
    label
    url
    route
    slug
  }
`;

export const HEADING_FIELDS = `
    _id
    label
    title
    titleFontSize
    boldDescription
    description
    ctaButton {
      ${BUTTON_FIELDS}
    }
    backgroundImage {
      ${IMAGE_FIELDS}
    }
    centerComponent
    showBackgroundImage
    spacing
`;

export const LOGO_COMPONENT_FIELDS = `
    _id
    title
    showGridLines
    gradientType
    spacingTop
    spacingBottom
    logosCollection {
      items {
        ${IMAGE_FIELDS}
      }
    }
`;

export const RICH_TEXT_FIELDS = `
    json
    links {
      assets {
        block {
          sys {
            id
          }
          url
          description
          width
          height
        }
      }
      entries {
        inline {
          sys {
            id
          }
          ${BUTTON_FIELDS}
          ... on DetailPage {
            title
            route
            slug
          }
        }
      }
    }
`;

export const DETAIL_PAGE_FIELDS = `
  ... on DetailPage {
    _id
    title
    slug
    sectionsCollection(limit: 10) {
      items {
        __typename
      }
    }
  }
`;

// ! CYWARE GENERIC QUERIES END

// ! CYWARE QUERIES START
export const ABOUT_DESCRIPTION_COMPONENT_FIELDS = `
    _id
    heading {
      ${HEADING_FIELDS}
    }
    cardsCollection {
      items {
        __typename
        _id
        title
        description
        underlineTitle 
        image {
          ${IMAGE_FIELDS}
        }
      }
    }
    description
`;

export const BLOG_LISTING_COMPONENT_FIELDS = `
    title
    numberOfCards
    backgroundType
    showGridLines
    spacingTop
    spacingBottom
`;

// 🖲️ HOME CARDS COMPONENT
export const CARDS_COMPONENT_FIELDS = `
    _id
    title
    showGridLines
    spacingTop
    spacingBottom
    cardsCollection {
      items {
        _id
        label
        title
        description
        route
        slug
        url
      }
    }
`;

export const COMPARISON_COMPONENT_FIELDS = `
    _id
    heading {
      ${HEADING_FIELDS}
    }
    ourProductTitle
    competitorProductTitle
    showGridLines
    backgroundType
    spacingTop
    spacingBottom
    comparisonContentCollection {
      items {
        ... on ComparisonItem {
          _id
          comparisonItemHeading
          comparisonCardsCollection {
            items {
              ... on ComparisonCard {
                _id
                cardType
                cardTextContent {
                  json
                }
              }
            }
          }
        }
      }
    }
`;

export const FAQ_COMPONENT_FIELDS = `
    _id
    label
    title
    description
    ctaButton {
      ${BUTTON_FIELDS}
    }
    accordionItemsCollection {
      items {
        question
        richTextAnswer {
        json
        }
        ctaButton {
          ${BUTTON_FIELDS}
        }
      }
    }
`;

export const FEATURED_IMAGE_COMPONENT_FIELDS = `
    _id
    label
    title
    description
    ctaButton {
      ${BUTTON_FIELDS}
    }
    featuredImageDesktop {
      ${IMAGE_FIELDS}
    }
    featuredImageMobile {
      ${IMAGE_FIELDS}
    }
    headingPosition
    imageType
    showBackgroundColor
    imageLottie
`;

export const FEATURED_TWO_IMAGE_COMPONENT_FIELDS = `
    _id
    title
    description
    ctaButton {
      ${BUTTON_FIELDS}
    }
    featuredImage {
      ${IMAGE_FIELDS}
    }
    imageLottie
    spacingTop
    spacingBottom
`;

export const FORM_COMPONENT_FIELDS = `
    label
    title
    formType
    submitUrl
    showGridLines
    backgroundType
    spacingTop
    spacingBottom
`;

export const HEADING_CARDS_COMPONENT_FIELDS = `
    _id
    showGridLines
    backgroundType
    spacing
    heading {
      ${HEADING_FIELDS}
    }
    cardsCollection {
      items {
        ... on ColorCard {
          __typename
          _id
          title
          description
          color
          headingSize
        }
        ... on TextWithImageCard {
          __typename
          _id
          image {
            ${IMAGE_FIELDS}
          }
          title
          description
          underlineTitle
        }
        ... on Tile {
          __typename
          _id
          label
          title
          description
          icon {
            ${IMAGE_FIELDS}
          }
          showIcon
          borderColor
          makeTitleLarge
          route
          slug
        }
        ... on TextDescriptionCardSmall {
          __typename
          _id
          title
          description
          underlineTitle
          boldDescription
          addBackground
          backgroundColor
          borderColor
        }
        ... on TextDescriptionCardLarge {
          __typename
          _id
          title
          description
          underlineTitle
          boldDescription
          addBackground
          backgroundColor
          borderColor
        }
        ... on ProductComparisonTable {
          __typename
          _id
          title
          }
        ... on CtaCard {
          __typename
          _id
          label
          title
          description
          makeTitleLarger
          backgroundColor
          accentColor
          ctaAction
          openTheLinkInANewTab
          route
          slug
          url
          image {
            ${IMAGE_FIELDS}
          }
        }
        ... on CtaCardLarge {
          __typename
          _id
          label
          title
          description
          backgroundColor
          accentColor
          ctaAction
          openTheLinkInANewTab
          route
          slug
          url
          image {
            ${IMAGE_FIELDS}
          }
        }
        ... on LocationItem {
          __typename
          _id
          country
          address
        }
        ... on RichTextCard {
          __typename
          _id
          text {
            json
          }
          headingColor
          backgroundColor
          borderColor
        }
      }
    }
    description
`;

export const HEADING_LISTING_COMPONENT_FIELDS = `
    heading {
      ${HEADING_FIELDS}
    }
    cardsCollection {
      items {
        _id
        title
        featuredImage {
          ${IMAGE_FIELDS}
        }
        date
        route
        slug
        url
      }
    }
    numberOfCards
    backgroundType
    showGridLines
    spacingTop
    spacingBottom
`;

const HEADING_LOGO_ITEM_FIELDS = `
  label
  logo {
    sys {
      id
    }
    ${IMAGE_FIELDS}
  }
`;

export const HEADING_LOGO_COMPONENT_FIELDS = `
    heading {
      ${HEADING_FIELDS}
    }
    featuredLogosCollection {
      items {
        ${HEADING_LOGO_ITEM_FIELDS}
      }
    }
    backgroundType
    showGridLines
    spacingTop
    spacingBottom
`;

export const HERO_COMPONENT_SINGLE_COLUMN_FIELDS = `
    _id
    title
    heroImageDesktop {
      ${IMAGE_FIELDS}
    }
    heroImageMobile {
      ${IMAGE_FIELDS}
    }
    showGridLines
    useCustomHeading
    gradientType
    spacingTop
    spacingBottom
    customHeading {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            url
            width
            height
            description
          }
        }
        entries {
          inline {
            sys {
              id
            }
            ${BUTTON_FIELDS}
          }
        }
      }
    }
`;

export const HERO_COMPONENT_TWO_COLUMN_FIELDS = `
    _id
    title
    subtitle
    ctaButton {
      ${BUTTON_FIELDS}
    }
    heroImageDesktop {
      ${IMAGE_FIELDS}
    }
    heroImageMobile {
      ${IMAGE_FIELDS}
    }
    gradientType
    showGridLines
    spacingTop
    spacingBottom
    cardsComponent {
      ${CARDS_COMPONENT_FIELDS}
    }
    logoComponent {
      ${LOGO_COMPONENT_FIELDS}
    }
`;

export const INVESTORS_COMPONENT_FIELDS = `
    _id
    title
    description
    spacingTop
    spacingBottom
    investorsCardsCollection {
      items {
        _id
        label
        title
        image {
          ${IMAGE_FIELDS}
        }
      }
    }
`;

export const LISTING_COMPONENT_FIELDS = `
    cardsCollection {
      items {
        _id
        title
        featuredImage {
          ${IMAGE_FIELDS}
        }
        date
        url
      }
    }
    numberOfCards
    backgroundType
    showGridLines
    spacingTop
    spacingBottom
`;

export const LISTING_TEXT_COMPONENT_FIELDS = `
    _id
    text {
      ${RICH_TEXT_FIELDS}
    }
    backgroundColor
    spacingTop
    spacingBottom
`;

export const MARQUEE_COMPONENT_FIELDS = `
    _id
    label
    title
    description
    ctaButton {
      ${BUTTON_FIELDS}
    }
    integrationLogosCollection {
      items {
        ${IMAGE_FIELDS}
      }
    }
    spacingTop
    spacingBottom
`;

export const NEWS_AND_PRESS_COMPONENT_FIELDS = `
    spacingTop
    spacingBottom
`;

export const NEWS_PRESS_CARDS_LISTING_FIELDS = `
  _id
  label
  title
  description
  date
  route
  slug
  url
`;

export const NEWS_PRESS_CARDS_DETAIL_FIELDS = `
  _id
  label
  title
  description
  body {
    ${RICH_TEXT_FIELDS}
  }
  date
  route
  slug
  url
`;

export const PRODUCT_COMPONENT_FIELDS = `
    _id
    title
    productImageDesktop {
      ${IMAGE_FIELDS}
    }
    useImage
    carouselTitle
    carouselDescription
    carouselItemsCollection {
      items {
      _id
        buttonTitle
        buttonIcon {
          ${IMAGE_FIELDS}
        }
        itemTitleDesktop
        itemTitleMobile
        itemDescription
        featuredImage {
          ${IMAGE_FIELDS}
        }
        linkLabel
        route
        slug
      }
    }
`;

export const REVIEWS_COMPONENT_FIELDS = `
    _id
    label
    title
    description
    spacingTop
    spacingBottom
    reviewCardsCollection {
      items {
        _id
        reviewerName
        reviewerLogo {
          ${IMAGE_FIELDS}
        }
        review
        rating
        color
        layout
      }
    }
`;

export const RICH_TEXT_COMPONENT_FIELDS = `
    _id
    text {
      ${RICH_TEXT_FIELDS}
    }
    twoColumnParagraphs
    centerHeadings
    justifyParagraphs
    centerParagraphs
    backgroundColor
    spacingTop
    spacingBottom
`;

export const STAFF_COMPONENT_FIELDS = `
    _id
    title
    description
    staffCardsCollection {
      items {
        _id
        name
        role
        linkedinUrl
        image {
          ${IMAGE_FIELDS}
        }
      }
    }
    spacingTop
    spacingBottom
`;

// ! Renamed 🖲️ Single Column Sections Component
// ! Items renamed to 🧩 Single Column Section Item
// ? imageLottie and imageType has been deprecated and removed from the query
export const STICKY_COMPONENT_FIELDS = `
    title
    subtitle
    twoColumnItemCollection {
      items {
        title
        description
        ctaButton {
          ${BUTTON_FIELDS}
        }
        featuredImage {
          ${IMAGE_FIELDS}
        }
      }
    }
    spacingTop
    spacingBottom
`;

export const THREAT_LISTING_COMPONENT_FIELDS = `
    title
    numberOfCards
    backgroundType
    showGridLines
    spacingTop
    spacingBottom
`;

export const TWO_COLUMN_COMPONENT_FIELDS = `
    _id
    switchColumns
    showBackgroundColor
    spacing
    ctaButton {
      ${BUTTON_FIELDS}
    }
    itemLeft {
      ${HEADING_FIELDS}
    }
    featuredImage {
      ${IMAGE_FIELDS}
    }
`;

export const TWO_COLUMN_STICKY_COMPONENT_FIELDS = `
    _id
    label
    title
    description
    spacingTop
    spacingBottom
    ctaButton {
      ${BUTTON_FIELDS}
    }
    stickyCardsCollection {
      items {
        _id
        title
        description
        borderColor
      }
    }
`;

export const USE_CASES_CARD_COMPONENT_FIELDS = `
    _id
    label
    title
    showTitleAndLabel
    numberOfCards
    cardsCollection {
      items {
        _id
        label
        title
        description
        route
        slug
        url
        accentColor
      }
    }
`;

// ! CYWARE QUERIES END
