import clsx from 'clsx';

import { ClientBlogListing } from '@/app/components/ui/Blog/ClientBlogListing';
import { SvgLinesComponent } from '@/app/components/ui/SvgLinesComponent';
import { getPaginatedBlogPages } from '@/app/lib/contentful/queries/api';
import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';
import type { ContentfulImage } from '@/app/types/contentful/types';

interface ListingComponentProps {
  content?: {
    blogListingComponent: {
      title: string;
      cardsCollection: {
        items: BlogListingCardProps[];
      };
      numberOfCards: number;
      backgroundType: string;
      showGridLines: boolean;
      spacingTop: number;
      spacingBottom: number;
    };
  };
}

interface BlogListingCardProps {
  _id: string;
  sys: {
    id: string;
  };
  title: string;
  description: string;
  date: string;
  route: string;
  slug: string;
  tags: string[];
  coverImage: ContentfulImage;
}

const backgroundTypeClasses: { [key: string]: string | string[] | null } = {
  None: 'bg-BLACK',
  'Slate Blue': 'bg-SLATE_BLUE',
  'Off Black': 'bg-OFF-BLACK',
  'Purple Gradient Top': 'bg-gradient-top-purple bg-top',
  'Blue Gradient Top': 'bg-gradient-top-blue bg-top',
  'Purple Gradient Center': 'bg-gradient-center-purple bg-center',
  'Blue Gradient Center': 'bg-gradient-center-blue bg-center',
  'Purple Gradient Bottom': 'bg-gradient-bottom-purple bg-bottom',
  'Blue Gradient Bottom': 'bg-gradient-bottom-blue bg-bottom',
  'Purple Gradient Top/Bottom': ['bg-gradient-top-purple bg-top', 'bg-gradient-bottom-purple bg-bottom'],
  'Blue Gradient Top/Bottom': ['bg-gradient-top-blue bg-top', 'bg-gradient-bottom-blue bg-bottom'],
} as const;

export default async function BlogListingComponent({ content }: ListingComponentProps): Promise<JSX.Element | null> {
  const initialBlogPages = await getPaginatedBlogPages(0, 9);

  if (!content) {
    return null;
  }

  const {
    blogListingComponent: { title, numberOfCards, backgroundType, showGridLines, spacingTop, spacingBottom },
  } = content;

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  const renderBackground = () => {
    const gradientClass = backgroundTypeClasses[backgroundType];

    if (Array.isArray(gradientClass)) {
      return (
        <>
          <div className={clsx('pointer-events-none absolute -z-10 h-full w-screen bg-cover bg-no-repeat', gradientClass[0])} />
          <div className={clsx('pointer-events-none absolute -z-10 h-full w-screen bg-cover bg-no-repeat', gradientClass[1])} />
        </>
      );
    }

    return gradientClass ? (
      <div className={clsx('pointer-events-none absolute left-0 top-0 -z-20 h-full w-screen bg-cover bg-no-repeat', gradientClass)} />
    ) : null;
  };

  return (
    <section className={`relative ${paddingTop} ${paddingBottom} scroll-mt-60`}>
      {showGridLines && (
        <div className="absolute left-0 top-0 -z-10 h-full w-full text-WHITE">
          <SvgLinesComponent />
        </div>
      )}
      {renderBackground()}

      <div className="container grid h-full grid-cols-12 gap-10 sm:gap-0">
        {title && (
          <h1 className='col-span-4 row-start-1 text-H3 text-WHITE md:col-span-full'>
            {title}
          </h1>
        )}

        <ClientBlogListing
          title={title}
          blogPagesCollection={initialBlogPages.items}
          totalBlogPages={initialBlogPages.total}
          numberOfCards={numberOfCards}
        />
      </div>
    </section>
  );
}
