import { getNewsPressCards } from '@/app/lib/contentful/queries/api';
import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';
import type { NewsPressListingsComponentProps } from '@/app/types/contentful/types';

import NewsPressListingsComponentClient from './NewsPressListingsComponentClient';

export default async function NewsPressListingsComponent({ content }: NewsPressListingsComponentProps) {
  const {
    newsPressListingsComponent: { spacingTop, spacingBottom },
  } = content;

  const cards = await getNewsPressCards();

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-4';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-4';

  return (
    <section
      className={`container relative grid h-full max-w-[1240px] grid-cols-12 gap-y-10 min-xl:max-w-[1440px] ${paddingTop} ${paddingBottom}`}
    >
      <NewsPressListingsComponentClient cards={cards} />
    </section>
  );
}
